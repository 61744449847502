<template>
	<div class="RightCont">
		<!-- <BigImg :visible="photoVisible" :url="bigImgUrl" @closeClick="()=>{photoVisible=false}"></BigImg> -->
		<!--消息接收-->
		<div :key="index" v-for="(item, index) in messageList_copy">
			<!--别人-->
			<div class="customerServiceDiv" v-if="item.sendPeople == 'other'">
				<div class="answerHead">
					<img v-if="isService == 'true'" src="../assets/images/visitor.png" />
					<img v-else :src="headImg" />
					<!-- ../assets/images/service_head.png -->
				</div>
				<div v-if="item.sendType == 1" class="answers">
					<img class="jiao" src="../assets/images/other_radio.jpg" />
					<div v-html="item.message" class="htmlDiv"></div>
				</div>
				<div v-else-if="item.sendType == 2" class="answers">
					<img class="jiao" src="../assets/images/other_radio.jpg" />
					<img v-bind:src="item.message" class="imgDiv"/>
				</div>
				<div v-else-if="item.sendType == 3" class="SendImage">
					<img v-bind:src="item.message" class="imgDiv"/>
					<!-- @click.self="showBigImage(item.message)" -->
				</div>
				<div v-else-if="item.sendType == 6" class="SendImage">
					<video v-bind:src="item.message" controls class="imgDiv"/>
				</div>
				<div v-else-if="item.sendType == 4" class="answers">
					<div v-html="item.message"></div>
					<div :key="index" v-for="(s, index) in item.answerList">
						<div v-html="s.title" v-on:click="robotClick(s.title,s.content)"></div>
					</div>
				</div>
			</div>

			<!--自己-->
			<div class="myselfDiv" v-if="item.sendPeople == 'me'">
				<div v-if="item.sendType == 1" class="news">
					<img class="jiao" src="../assets/images/radio.jpg" />
					<div v-html="item.message" class="htmlDiv"></div>
				</div>
				<div v-else-if="item.sendType == 2" class="news">
					<img class="jiao" src="../assets/images/radio.jpg" />
					<img v-bind:src="item.message" class="imgDiv"/>
				</div>
				<div v-else-if="item.sendType == 3" class="SendImage">
					<img v-bind:src="item.message" class="imgDiv"/> <!-- @click.self="showBigImage(item.message)" /> -->
				</div>
				<div v-else-if="item.sendType == 6" class="SendImage">
					<video v-bind:src="item.message" controls class="imgDiv"/>
				</div>
				<div v-else-if="item.sendType == 4" class="answers">
					<div v-html="item.message"></div>
					<div :key="index" v-for="(s, index) in item.answerList">
						<div v-html="s.title" v-on:click="robotClick(s.title,s.content)"></div>
					</div>
				</div>
				<div class="nesHead">
					<!-- <img v-if="isService == 'true'" src="../assets/images/service_head.png" /> -->
					<img v-if="isService == 'true'" :src="headImg" />
					<img v-else src="../assets/images/visitor.png" />
				</div>
			</div>

			<!--状态通知显示-->
			<div v-if="item.sendPeople == 'notice'" class="noticeDiv">
				<!-- <div class="moreSession" v-if="item.sendType == 4">
					{{ item.message }}
				</div> -->
				<div class="showTime" v-if="item.sendType == 5">
					{{ item.message }}
				</div>
			</div>

			<div style="clear: both"></div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import config from '../config';
	//import BigImg from "./BigImg"
	export default {
		name: 'MessageWindow',
		props: {
			messageList: Object,
			sendId: String,
			receiveId: String,
			isService: String,
			serviceHead: String,
		},
		data(){
			return{
				socket: this.$store.state.socket,
				lastSession: true,
				idIsSelect: [],
				//此处对于子组件与父组件之间的传值需要拷贝一下，父组件传值子组件实际上还是引用地址，不能直接去修改
				messageList_copy: this.messageList,
				headImg: ''
			}
		},
		/* components: {
			BigImg
		}, */
		mounted() {
			this.headImg = this.serviceHead == '' || this.serviceHead == undefined ? config.apiUrl +
				'/upload/images/service_head.png' : config.apiUrl + this.serviceHead;
		},
		methods: {
			selectMessage() {
				if (this.sendId != undefined && this.receiveId != undefined) {
					let params = {
						sendId: this.sendId,
						receiveId: this.receiveId
					}
					axios({
						method: 'post',
						url: '/selectMessage',
						data: params
					}).then((response) => {
						if (response.data[0].code) {
							let message = JSON.parse(response.data[0].data);
							this.messageList_copy = [];
							for (var i = message.length - 1; i >= 0; i--) {
								if (message[i].sendId == this.sendId) {
									let obj = {
										sendType: message[i].sendType,
										sendPeople: 'me',
										message: message[i].sendMessage
									}
									this.messageList_copy.unshift(obj)
								} else {
									let obj = {
										sendType: message[i].sendType,
										sendPeople: 'other',
										message: message[i].sendMessage
									}
									this.messageList_copy.unshift(obj)
								}
								let obj = {
									sendType: 5,
									sendPeople: 'notice',
									message: message[i].sendTime
								}
								this.messageList_copy.unshift(obj)
							}
							//对已经加载了消息的id记录下来
							let obj = {
								receiveId: this.receiveId
							}
							this.idIsSelect.push(obj)
							this.lastSession = false
						} else {
							this.lastSession = false
							//this.$toast(response.data[0].message);
						}
					})
				}
			},
			robotClick(title, content) {
					let obj = {}
					obj.sendType = 1
					obj.sendPeople = 'me'
					obj.message = title;
					this.messageList_copy.push(obj)
					let objs = {}
					objs.sendType = 1
					objs.sendPeople = 'other'
					objs.message = content;
					this.messageList_copy.push(objs)
					this.toBottom(200)
					//向socket发送数据请求
					let user={}
					user.userId=this.sendId;
					user.receiveId=this.receiveId;
					user.message = title;
					user.sendType = 1;
					user.sendUserType = 2;
					this.socket.emit("sendMessage", user);
					let service={}
					service.serviceId=this.receiveId;
					service.receiveId=this.sendId;
					service.message = content;
					service.sendType = 1;
					service.sendUserType = 3;
					this.socket.emit("sendMessage", service);
			},
			//回到底部
			toBottom(time) {
				setTimeout(() => {
					let RightCont = document.getElementById("customerChatWindow");
					if (RightCont != null) {
						let scrollHeight2 = RightCont.scrollHeight;
						RightCont.scrollTop = scrollHeight2;
					}
				}, time);
				clearTimeout();
			},
			/* showBigImage(e) { //点击图片函数，点击后，把photoVisible设置成true
				if (e != "") {
					this.photoVisible = true;
					this.bigImgUrl = e;
				}
			} */
		},
		watch: {
			//此处需要根据客服的选择，进行实时刷新判断是否已经加载历史消息，否则可以多次加载消息，消息列表重复
			receiveId: {
				handler(newValue, oldValue) {
					if (oldValue != undefined) {
						let listTemp = this.idIsSelect.filter((v) => v.receiveId == this.receiveId)
						if (listTemp.length == 0) {
							this.lastSession = true
						} else {
							this.lastSession = false
						}
						if (listTemp.length == 0 && this.lastSession) {
							let params = {
								sendId: this.sendId,
								receiveId: this.receiveId
							}
							axios({
								method: 'post',
								url: '/selectMessage',
								data: params
							}).then((response) => {
								if (response.data[0].code) {
									let message = JSON.parse(response.data[0].data);
									if (message.length == 0) {
										this.lastSession = false
									}
								} else {
									this.lastSession = false
									this.$toast(response.data[0].message);
								}
							})
						}
					}
				},
				deep: true,
				immediate: true
			},
			//解决父组件传值拷贝不会更新
			messageList: {
				handler() {
					this.messageList_copy = this.messageList
				},
				deep: true,
				immediate: true
			},
			serviceHead: {
				handler() {
					this.headImg = this.serviceHead == '' || this.serviceHead == undefined ? config.apiUrl +
						'/upload/images/service_head.png' : config.apiUrl + this.serviceHead;
				},
				deep: true,
				immediate: true
			},

		}

	}
</script>
<style >
	@import url("../assets/css/MessageWindow.css");

	.htmlDiv img {
		max-width: 300px;
		max-height: auto;
	}
	.htmlDiv video{
		max-width: 300px;
		max-height: auto;
	}
	
	.imgDiv img {
		max-width: 600px;
		max-height: auto;
	}
	.imgDiv video{
		max-width: 600px;
		max-height: auto;
	}
</style>