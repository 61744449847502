<template>
	<div class="main">
		<!-- <span class="goHome" @click="goHome">&lt; 返 回</span> -->
		<h2 class="title">话术批量复制</h2>
		<el-form :model="service">
			<el-form-item label="原始卡密">
				<el-input v-model="srcServicePwd" placeholder="请输入需要复制的卡密"></el-input>
			</el-form-item>
			<el-form-item label="目标卡密">
				<el-input type="textarea" :rows="10" v-model="tgtServicePwds"
					placeholder="请输入目标卡密，一行一个卡密,支持多个,按回车换行"></el-input>
			</el-form-item>
			<el-form-item label="复制内容">
				<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
					@change="handleCheckAllChange">全选</el-checkbox>
			</el-form-item>
			<el-form-item>
				<el-checkbox-group v-model="checkedOptions" @change="handleCheckedOptionsChange">
					<el-checkbox v-for="myOption in myOptions" :label="myOption"
						:key="myOption">{{myOption}}</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item>
				<el-button type="success" v-on:click="batchCopy()" style="width:100%">一键复制</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import axios from 'axios';
	const options = ['头像', '昵称', '打招呼', '快捷回复', '智能问答', '群发'];
	export default {
		data() {
			return {
				srcServicePwd: '',
				tgtServicePwds: '',
				checkAll: false,
				checkedOptions: options,
				myOptions: options,
				isIndeterminate: true
			}
		},
		methods: {
			goHome() {
				this.$router.push({
					path: '/fansWork',
					replace: true
				})
			},
			handleCheckAllChange(val) {
				this.checkedOptions = val ? options : [];
				this.isIndeterminate = false;
			},
			handleCheckedOptionsChange(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.myOptions.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.myOptions.length;
			},
			batchCopy() {
				if (this.srcServicePwd==''){
					this.$toast("原始卡密不能为空")
					return
				}
				if (this.tgtServicePwds=='' || this.tgtServicePwds==undefined){
					this.$toast("目标卡密不能为空")
					return
				}
				let confIds = '';
				for (var i = 0; i < this.checkedOptions.length; i++) {
					switch (this.checkedOptions[i]) {
						case '头像':
							confIds = confIds == '' ? '0' : confIds + ',0';
							break;
						case '昵称':
							confIds = confIds == '' ? '9' : confIds + ',9';
							break;
						case '打招呼':
							confIds = confIds == '' ? '1' : confIds + ',1';
							break;
						case '快捷回复':
							confIds = confIds == '' ? '2' : confIds + ',2';
							break;
						case '智能问答':
							confIds = confIds == '' ? '3' : confIds + ',3';
							break;
						case '群发':
							confIds = confIds == '' ? '4' : confIds + ',4';
							break;
					}
				}
				if (confIds==''){
					this.$toast("复制内容不能为空")
					return
				}
						
				let tgtServicePwds = this.tgtServicePwds.split("\n"); //转成数组
				let params = {
					srcServicePwd: this.srcServicePwd,
					tgtServicePwds: tgtServicePwds.filter((item) => item),
					confIds: confIds
				}
				console.log(params.tgtServicePwds);
				axios({
					method: 'post',
					url: '/batchConf',
					data: params
				}).then((response) => {
					if (response.data[0].code) {
						this.$toast("一键复制成功")
					} else {
						this.$toast("一键复制失败，请检查卡密是否正确")
					}
				})
			}
		}
	}
</script>