<template>
	<div class="modal-background1">
		<div class="modal1">
			<h2 class="title">域名信息</h2>
			<el-form size="default" label-width="90px">
				<el-row :gutter="35">
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="域  名">
							<el-input v-model="hostName" placeholder="请输入域名" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
						<el-form-item label="备注">
							<el-input v-model="memo" placeholder="备注信息"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="default">取 消</el-button>
					<el-button type="primary" @click="onSubmit" size="default">保  存</el-button>
				</span>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus';
	import axios from 'axios';

	export default {
		components: {

		},
		props: {
			info: Object
		},
		data() {
			return {
				staff:{},
				hostId:0,
				hostName:'',
				memo:'',
				dialog: {
					isShowDialog: false,
					editMode: false,
					title: '',
					submitTxt: '',
				},
			}
		},
		mounted() {
			if (JSON.parse(localStorage.getItem('staffData')) != null) {
				this.staff = Object.assign({}, JSON.parse(localStorage.getItem('staffData')))
			} else {
				this.$router.push({
					path: '/admin/login',
					replace: true
				})
			}
			let info = this.info ;
			if (info!=null) {
				this.dialog.editMode=true;
				this.hostId = info.hostId;
				this.hostName = info.hostName;
				this.memo = info.memo;
			} else {
				this.dialog.editMode=false;
				this.hostId =0;
				this.hostName = '';
				this.memo = '';
			}
			
		},
		methods: {
			onCancel(){
				this.closeDialog();
			},
			// 关闭弹窗
			closeDialog() {
				this.$emit('closeDlg');
			},
			// 取消
			onCance() {
				this.closeDialog();
			},
			// 提交
			onSubmit() {
				let that=this;
				if (this.hostName.trim() == '') {
					ElMessage.error('域名不能为空');
					return false;
				}

				if (this.dialog.editMode){
					let data = {
						hostId: this.hostId,
						hostName: this.hostName,
						memo: this.memo
					}
					axios({
							url: '/updateHost',
							method: 'post',
							data: data
						}).then(function(res) {
							if (res.data[0].code > 0) {
								ElMessage.success('保存成功！');
							} else {
								ElMessage.warning('保存失败！');
							}
							that.closeDialog();
						})
						.catch(() => {});
				}else{
					let data = {
						hostName: this.hostName,
						memo: this.memo
					}
					axios({
							url: '/addHost',
							method: 'post',
							data: data
						}).then(function(res) {
							if (res.data[0].code > 0) {
								ElMessage.success('保存成功！');
								that.closeDialog();
							} else {
								ElMessage.warning('保存失败！');
							}
						})
						.catch(() => {});
				}
				
			}
		}
	}
</script>