<template>
	<div class="modal-background1">
		<div class="modal1">
			<el-form ref="userDialogFormRef" :model="ruleForm" size="default" label-width="90px">
				<el-row :gutter="35">
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="数量">
							<el-input-number v-model="ruleForm.amount" placeholder="数量"></el-input-number>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span class="dialog-footer">
				<el-button @click="onCancel" size="default">取 消</el-button>
				<el-button type="primary" @click="onSubmit" size="default">确 定</el-button>
			</span>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus';
	import axios from 'axios';

	export default {
		components: {

		},
		data() {
			return {
				staff: {},
				ruleForm: {
					amount: 10
				},
				dialog: {
					isShowDialog: false,
					editMode: true,
					title: '',
					submitTxt: '',
				}
			}
		},
		mounted() {
			if (JSON.parse(localStorage.getItem('staffData')) != null) {
				this.staff = Object.assign({}, JSON.parse(localStorage.getItem('staffData')))
			} else {
				this.$router.push({
					path: '/admin/login',
					replace: true
				})
			}
		},
		methods: {
			// 关闭弹窗
			closeDialog() {
				this.$emit('closeBatchDlg');
			},
			// 取消
			onCancel() {
				this.closeDialog();
			},
			// 提交
			onSubmit() {
				let that=this;
				if (this.ruleForm.amount <= 0) {
					ElMessage.error('数量必须大于0');
					return false;
				}

				let data = {
					count: this.ruleForm.amount,
					staffId: this.staff.Staff_ID
				}
				axios({
						url: '/bactchCard',
						method: 'post',
						data: data
					}).then(function(res) {
						if (res.data[0].code > 0) {
							ElMessage.success('处理成功！');
							that.closeDialog();
						} else {
							ElMessage.warning('处理失败！');
						}
					})
					.catch(() => {});
			}
		}
	}
</script>