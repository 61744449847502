<template>
	<el-table style="width: 100%;height:100%;" stripe>
		<el-table-column v-for="(col,index) in $attrs.config" :key="index" v-bind="col">
			<template v-if="col.slot" #default="scope">
				<slot :name="col.slot" :row="scope.row"></slot>
			</template>
		</el-table-column>
	</el-table>
</template>
<script lang="ts" setup>
</script>