<template>
	<el-row :gutter="20">
		<el-col :span="18" style="color:#fff;font-size: 24px ;font-weight : bold;" width="50%">客服系统后台管理</el-col>
		<el-col :span="6">
			<el-dropdown style="color:#fff;margin-top: 15px;border: 0;float: right;" @command="handleCommand">
				<span><el-icon>
						<UserFilled />
					</el-icon>
					{{staffName}}
					<el-icon><ArrowDown /></el-icon>
				</span>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="1">修改密码</el-dropdown-item>
						<el-dropdown-item command="2">退出系统</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</el-col>
	</el-row>
</template>

<script>
	import {
		UserFilled,ArrowDown
	} from "@element-plus/icons-vue";
	export default {
		components: {
			UserFilled,
			ArrowDown
		},
		props: {
			staffName: String
		},
		methods:{
			handleCommand(key) {
				switch (key) {
					case "1":
						this.$router.push({
							path: '/admin/modifyPwd',
							replace: true
						})
						break;
					case "2":
						localStorage.setItem('staffData', null)
						//页面跳转
						this.$router.push({
							path: '/admin/login',
							replace: true
						})
						break;
			
				}
			
			}
		},
		watch: {
			staffName: {
				handler() {
					//this.staffName = this.staffName
				},
				deep: true,
				immediate: true
			},

		}
	}
</script>