<template>
	<div class="modal-background1">
		<div class="modal1">
			<span class="close" @click="closeMe">&times;</span>
			<h2 class="title">信息内容</h2>
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="创建时间">
					<el-input v-model="formInline.createTime" :disabled="true" style="width:200px"></el-input>
				</el-form-item>
				<el-form-item style="width:125px" />
				<el-form-item label="IP地址">
					<el-input v-model="formInline.content" style="width:200px"></el-input>
				</el-form-item>
			</el-form>
			<div class="row">
				<el-button type="success" @click="onConfirm" class="btn">确 定</el-button>
				<el-button type="success" @click="closeMe" class="btn">取 消</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';

	export default {
		props: {
			info: Object,
			serviceId: String
		},
		
		data() {
			return {
				formInline: {
					confId: 0,
					createTime: '',
					confKind:5,
					infoKind: 1,
					title:'',
					content: ''
				}
			}
		},
		mounted() {
			let info = this.info;
			if (info != null) {
				this.formInline.confId = info.confId;
				this.formInline.createTime = info.createTime;
				this.formInline.content = info.content;
			} else {
				this.formInline.content = '';
				this.formInline.confId = 0;
			}
		},
		methods: {
			closeMe() {
				this.$emit('closeDlg');
			},
			onConfirm() {
				if (this.formInline.confId==0){
					this.addConf();
				}else{
					this.updateConf();
				}
			},
			addConf() {
				let params = {
					serviceId: this.serviceId,
					confKind: 5,
					infoKind: 0,
					title: '',
					content: this.formInline.content
				}
				axios({
					method: 'post',
					url: '/addConf',
					data: params
				}).then((response) => {
					this.$emit('closeDlg');
					if (response.data[0].code) {
						this.$toast("新增成功")
					} else {
						this.$toast("新增失败")
					}
				})
			},
			updateConf() {
				let params = {
					confId: this.formInline.confId,
					serviceId: this.serviceId,
					infoKind: 0,
					title: '',
					content: this.formInline.content
				}
				axios({
					method: 'post',
					url: '/updateConf',
					data: params
				}).then((response) => {
					this.$emit('closeDlg');
					if (response.data[0].code) {
						this.$toast("修改成功")
					} else {
						this.$toast("修改失败")
					}
				})
			},
		}
	};
</script>

<style>
	.modal-background1 {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 1100;
	}

	.modal1 {
		background: #fff;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		position: relative;
		width: 800px;
		height: 200px;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	.row {
		font-size: 14px;
		height: 70px;
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	.btn {
		margin: 30px;
	}
</style>