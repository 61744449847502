<template>
	<div class="container">
		<h2 class="title">修改密码</h2>
		<el-form>
			<el-form-item label="旧&nbsp;&nbsp;密&nbsp;&nbsp;码">
				<el-input v-model="oldPwd" type="password"></el-input>
			</el-form-item>
			<el-form-item label="新&nbsp;&nbsp;密&nbsp;&nbsp;码">
				<el-input v-model="newPwd" type="password"></el-input>
			</el-form-item>
			<el-form-item label="确认密码">
				<el-input v-model="newPwd1" type="password"></el-input>
			</el-form-item>
		</el-form>
		<el-row>
			<el-button type="success" @click="onConfirm" class="btn">确 定</el-button>
		</el-row>
	</div>
</template>


<script>
	import axios from 'axios';

	export default {
		components: {

		},
		data() {
			return {
				oldPwd: '',
				newPwd: '',
				newPwd1: ''
			}
		},

		mounted() {
			if (JSON.parse(localStorage.getItem('staffData')) != null) {
				this.staff = Object.assign({}, JSON.parse(localStorage.getItem('staffData')))
			} else {
				this.$router.push({
					path: '/admin/login',
					replace: true
				})
			}
		},

		methods: {
			onConfirm() {
				if (this.oldPwd == null) {
					this.$toast("旧密码不能为空!");
					return;
				}
				if (this.newPwd == null) {
					this.$toast("新密码不能为空!");
					return;
				}
				if (this.newPwd != this.newPwd1) {
					this.$toast("两次输入密码不一致!");
					return;
				}
				//数据加密
				let params = {
					id: this.staff.Staff_ID,
					newPwd: this.newPwd,
					oldPwd: this.oldPwd
				}

				axios({
					method: 'post',
					url: '/modifyPwd',
					data: params
				}).then((response) => {
					console.log(response.data[0].data)
					if (response.data[0].code) {
						if (response.data[0].data) {
							this.$router.push({
								path: '/admin/main',
								replace: true
							})
						} else {
							this.$toast("请检查旧密码是否正确")
						}
					} else {
						this.$toast("密码修改失败，请检查旧密码是否正确")
					}
				})
			}
		}
	}
</script>

<style>
	.el-header {
		background-color: dodgerblue;
		color: #333;
		max-height: 50px;
		line-height: 50px;
	}

	.el-aside {
		color: #333;
		background-color: aliceblue;
	}
</style>