<template>
	<div class="errContainer">
		<div class="main">
			<h2 class="title">{{msg}}</h2>
		</div>
	</div>
</template>

<script>
	import { useRoute } from 'vue-router';
	export default {
		created(){
			const route = useRoute();
			this.msg = route.query.msg;
			if (this.msg==undefined){
				this.msg='口令失效或禁止访问'
			}
		},
		data() {
			return {
				msg:'1'
			}
		}
	}
</script>

<style>
	.errContainer{
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		background: #fff;
		cursor: pointer;
	}
	.errContainer .main {
		width: 500px;
		height: 300px;
		margin-top: 200px;
	}
</style>