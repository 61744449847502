<template>
	<el-row class="tac">
			<el-input size="default" v-model="actionForm.loginName" placeholder="请输入用户名" style="max-width: 180px;margin-right: 10px;">
			</el-input>
			<el-button size="default" type="primary" class="ml10" @click="getList">
				<el-icon>
					<ele-Search />
				</el-icon>
				查询
			</el-button>
			<el-button size="default" type="success" class="ml10" @click="onOpenAddUser()">
				<el-icon>
					<ele-FolderAdd />
				</el-icon>
				新增用户
			</el-button>
	</el-row>
	<el-row>
		<el-table :data="tableData.data" v-loading="tableData.loading" style="width: 100%;height: 750px;">
			<el-table-column type="index" label="序号" width="60" />
			<el-table-column prop="loginName" label="登录名" width="120" show-overflow-tooltip></el-table-column>
			<el-table-column prop="userName" label="用户名" width="120" show-overflow-tooltip></el-table-column>
			<el-table-column prop="sexName" label="性别" width="60" show-overflow-tooltip></el-table-column>
			<el-table-column prop="role" label="角色" width="120" show-overflow-tooltip></el-table-column>
			<el-table-column prop="online" label="在线状态" width="100" show-overflow-tooltip></el-table-column>
			<el-table-column prop="phone" label="手机号" width="120" show-overflow-tooltip></el-table-column>
			<el-table-column prop="accessCount" label="访问次数" width="100" show-overflow-tooltip></el-table-column>
			<el-table-column prop="status" label="状态" width="100" show-overflow-tooltip>
				<template #default="scope">
					<el-tag type="success" v-if="scope.row.status==1">{{scope.row.statusName}}</el-tag>
					<el-tag type="info" v-else>{{scope.row.statusName}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="创建时间" width="180" show-overflow-tooltip></el-table-column>
			<el-table-column prop="describe" label="备注" show-overflow-tooltip></el-table-column>
			<el-table-column label="操作" width="140">
				<template #default="scope">
					<el-button :disabled="scope.row.roleId <2" size="small" type="primary"
						@click="onOpenEditUser(scope.row)">修改</el-button>
					<el-button :disabled="scope.row.roleId<2" size="small" type="success"
						@click="onRowDel(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
	</el-row>
	<el-row>
		<el-pagination @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange" class="mt15"
			:pager-count="5" :page-sizes="[10, 20, 30]" v-model:current-page="tableData.param.pageNum" background
			v-model:page-size="tableData.param.pageSize" layout="total, sizes, prev, pager, next, jumper"
			:total="tableData.total">
		</el-pagination>
	</el-row>
	<staffDlg v-if="showDlg" @closeDlg="closeDlg" :info="selectInfo" />
</template>

<script>
	import {
		ElMessageBox
	} from 'element-plus';
	import moment from 'moment';
	import axios from 'axios';
	import staffDlg from './staffDlg.vue';
	export default {
		components: {
			staffDlg
		},
		data() {
			return {
				showDlg: false,
				selectInfo: {},
				actionForm: {
					loginName: '',
				},
				tableData: {
					data: [],
					total: 0,
					loading: false,
					param: {
						pageNum: 1,
						pageSize: 10,
					},
				}
			}
		},
		mounted() {
			this.getList();
		},
		methods: {
			getList() {
				this.tableData.loading = true;
				let that=this;
				axios({
						url: '/getStaffList',
						method: 'post',
						data: {
							loginName: this.actionForm.loginName,
							pageNum: this.tableData.param.pageNum,
							pageSize: this.tableData.param.pageSize
						}
					}).then(function(res) {
						let resData=JSON.parse(res.data[0].data) ;
						if (res.data[0].code) {
							const data = [];
							for (var i = 0; i < resData.data.length; i++) {
								let sb = true;
								if (resData.data[i].State != 1) {
									sb = false;
								}
								data.push({
									staffId: resData.data[i].Staff_ID,
									loginName: resData.data[i].Login_Name,
									userName: resData.data[i].Staff_Name,
									pwd: '',
									role: resData.data[i].Role_Name,
									roleId: resData.data[i].Role_ID,
									online: resData.data[i].Online_Name,
									phone: resData.data[i].Mobile,
									accessCount: resData.data[i].Access_Count,
									sex: resData.data[i].Sex,
									sexName: resData.data[i].Sex_Name,
									accessHost: resData.data[i].Access_Host,
									accessTime: moment(resData.data[i].Access_Time).format('YYYY-MM-DD HH:mm:ss'),
									status: resData.data[i].State,
									statusBool: sb,
									statusName: resData.data[i].State_Name,
									describe: resData.data[i].Memo,
									createTime: moment(resData.data[i].Create_Time).format('YYYY-MM-DD HH:mm:ss')
								});
							}
							that.tableData.data = data;
							that.tableData.total = resData.total;
						} else {
							that.$toast('获取列表失败！');
						}
						that.tableData.loading = false; 
					})
					.catch((e) => {
						console.log(e)
						this.$toast('获取列表失败！');
						this.tableData.loading = false;
					});
			},

			// 打开新增用户弹窗
			onOpenAddUser() {
				this.selectInfo = {};
				this.showDlg = true;
			},
			// 打开修改用户弹窗
			onOpenEditUser( row) {
				this.selectInfo = row;
				this.showDlg = true;
			},
			closeDlg() {
				this.showDlg = false;
				this.getList();
			},
			// 删除用户
			onRowDel(row) {
				let that=this;
				ElMessageBox.confirm(`此操作将删除用户“${row.userName}”，是否继续?`, '提示', {
						confirmButtonText: '确认',
						cancelButtonText: '取消',
						type: 'warning',
					})
					.then(() => {
						axios({
								url: '/delStaff',
								method: 'post',
								data: {
									staffId: row.staffId
								}
							}).then(function(res) {
								if (res.data[0].code > 0) {
									that.getList();
									that.$toast('删除成功');
								} else {
									that.$toast('删除失败');
								}
							})
							.catch((e) => {console.log(e)});
					});
			},

			// 分页改变
			onHandleSizeChange(val) {
				this.tableData.param.pageSize = val;
				this.getList();
			},
			// 分页改变
			onHandleCurrentChange(val) {
				this.tableData.param.pageNum = val;
				this.getList();
			}
		}
	}
</script>