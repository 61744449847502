<template>
	<div class="modal-background1">
		<div class="modal1">
			<span class="close" @click="closeMe">&times;</span>
			<h2 class="title">信息内容</h2>
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="创建时间">
					<el-input v-model="formInline.createTime" :disabled="true" style="width:200px"></el-input>
				</el-form-item>
				<el-form-item style="width:125px" />
				<el-form-item label="信息类型">
					<el-select v-model="formInline.infoKind" ref="infoKind" placeholder="请选择" style="width:200px">
						<el-option label="文本" value=1></el-option>
						<el-option label="图片" value=2></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="信息标题">
					<el-input v-model="formInline.title" style="width:700px"></el-input>
				</el-form-item>
			</el-form>
			<div class="container1">
				<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
					:mode="mode" />
				<Editor style="height: 320px; overflow-y: hidden; text-align: left;" v-model="formInline.content"
					:defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
			</div>
			<div class="row">
				<el-button type="success" @click="onConfirm" class="btn">确 定</el-button>
				<el-button type="success" @click="closeMe" class="btn">取 消</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import emojiJSON from '@/assets/data/emoji.json'
	import config from '../config';

	export default {
		props: {
			info: Object,
			serviceId: String,
			kind: Object
		},
		components: {
			Editor,
			Toolbar
		},

		data() {
			return {
				emojiList: emojiJSON,
				formInline: {
					confId: 0,
					createTime: '',
					confKind: 0,
					infoKind: '',
					title: '',
					content: ''
				},
				editor: null,
				toolbarConfig: {},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						emotion: {
							emotions: '❤️‍ ✈️ ⚡️ ☀️ ☘️ ㊙️ ✔️ ❌ ⭕️ ⛔️ ♻️ ☎️ ⏰ ⌛️ ⏳ 😃 😄 😁 😆 😅 😂 🤣 😊 😇 🙂 🙃 😉'
								.split(' ')
						},
						uploadImage: {
							server: config.apiUrl+'/uploadImg',
							// form-data fieldName ，默认值 'wangeditor-uploaded-image'
							fieldName: 'file',
							// 单个文件的最大体积限制，默认为 2M
							maxFileSize: 20 * 1024 * 1024, // 1M
							// 最多可上传几个文件，默认为 100
							maxNumberOfFiles: 10,
							// 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
							allowedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
							// 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
							/* meta: {
								accessToken: this.getStore("accessToken"),
								// otherKey: 'yyy'
							}, */
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
							// 自定义增加 http  header
							headers: {
								// Accept: 'application/json',
								// otherKey: 'xxx'
							},
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
							// 超时时间，默认为 10 秒
							timeout: 20 * 1000, // 5 秒
							base64LimitSize: 5 * 1024, //小于5K的用base64位表示
							customInsert: this.customInsertFn // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
						},
						uploadVideo: {
							server: config.apiUrl+'/uploadVideo',
							// form-data fieldName ，默认值 'wangeditor-uploaded-image'
							fieldName: 'file',
							// 单个文件的最大体积限制，默认为 2M
							maxFileSize: 40 * 1024 * 1024, // 1M
							// 最多可上传几个文件，默认为 100
							maxNumberOfFiles: 5,
							// 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
							allowedFileTypes: ['video/mp4', 'video/avi','video/mov','video/mpeg','video/flv'],
							// 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
							/* meta: {
								accessToken: this.getStore("accessToken"),
								// otherKey: 'yyy'
							}, */
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
							// 自定义增加 http  header
							headers: {
								// Accept: 'application/json',
								// otherKey: 'xxx'
							},
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
							// 超时时间，默认为 10 秒
							timeout: 20 * 1000, // 5 秒
							customInsert: this.customInsertFn // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
						}
					}
				},
				mode: 'default', // or 'simple'
			}
		},
		mounted() {
			let info = this.info;
			if (info != null) {
				this.formInline.confId = info.confId;
				this.formInline.createTime = info.createTime;
				this.formInline.infoKind = String(info.infoKind);
				this.formInline.title = info.title;
				setTimeout(() => {
					this.formInline.content = info.content;
				}, 100)
			} else {
				this.formInline.title = '';
				this.formInline.content = '';
				this.formInline.confId = 0;
			}
		},
		methods: {
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			},
			beforeDestroy() {
				const editor = this.editor
				if (editor == null) return
				editor.destroy() // 组件销毁时，及时销毁编辑器
			},
			closeMe() {
				this.$emit('closeDlg');
			},
			onConfirm() {
				if (this.formInline.infoKind == '') {
					this.$toast("类型不能为空")
					return;
				}
				//this.handleGetHtml();
				if (this.formInline.confId == 0) {
					this.addConf();
				} else {
					this.updateConf();
				}
			},
			customInsertFn(res, insertFn) {
				if (res.path != undefined) {
					let url = config.apiUrl+res.path;
					insertFn(url);
					//this.$message.success("上传成功");
				} else {
					this.$message.error('文件类型错误或超出大小限制');
				}
			},
			addConf() {
				let params = {
					serviceId: this.serviceId,
					confKind: this.kind.id,
					infoKind: this.formInline.infoKind,
					title: this.formInline.title,
					content: this.formInline.content
				}
				axios({
					method: 'post',
					url: '/addConf',
					data: params
				}).then((response) => {
					this.$emit('closeDlg');
					if (response.data[0].code) {
						this.$toast("新增成功")
					} else {
						this.$toast("新增失败")
					}
				})
			},
			updateConf() {
				let params = {
					confId: this.formInline.confId,
					serviceId: this.serviceId,
					infoKind: this.formInline.infoKind,
					title: this.formInline.title,
					content: this.formInline.content
				}
				console.log(this.formInline)
				axios({
					method: 'post',
					url: '/updateConf',
					data: params
				}).then((response) => {
					this.$emit('closeDlg');
					if (response.data[0].code) {
						this.$toast("修改成功")
					} else {
						this.$toast("修改失败")
					}
				})
			},
		}
	}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style>
	.modal-background1 {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 99;
	}

	.modal1 {
		background: #fff;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		position: relative;
		width: 800px;
		height: 50%;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	.close1 {
		position: absolute;
		top: 10px;
		right: 20px;
		cursor: pointer;
		font-size: 24px;
	}

	.title1 {
		width: 100%;
		height: 50px;
	}

	.container1 {
		width: 98%;
		height: 52%;
		border: 1px solid #f1f1f1;
		display: flex;
		flex-direction: column;
	}

	.editor {
		width: 100%;
		height: 70%
	}

	.row {
		font-size: 14px;
		height: 70px;
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	.col2 {
		width: 50%;
		display: flex;
	}

	.input {
		height: 32px;
		width: 100px;
	}

	.btn {
		margin: 30px;
	}
</style>