<template>
	<div class="modal-background">
		<div class="modal2">
			<span class="close" @click="closeMe">&times;</span>
			<h2 class="title">专属二维码</h2>
			<div v-if="qrUrl!=''">
				<span>{{qrUrl}}</span> <el-button type="success" text @click="copy">复制链接</el-button>
			</div>
			<div style="height:200px;" v-else>
				<span style="line-height: 200px;">{{msg}}</span>
			</div>
			<div>
				<img :src="qrCodeData" alt="QR Code" class="qr">
			</div>
			<h4>今日新增用户：{{count}}</h4>
			<div class="row">
				<el-button type="success" @click="closeMe" class="btn1">关 闭</el-button>
				<el-button type="success" @click="disableQr" class="btn1">失 效</el-button>
				<el-button type="success" @click="resetQr" class="btn1">重新生成</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import qrcode from 'qrcode';
	export default {
		props: {
			serviceId: String
		},
		data() {
			return {
				qrCodeData: '',
				count: 0,
				serviceId_copy: '',
				qrUrl: '',
				qrCode: '',
				msg:''
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			closeMe() {
				this.$emit('closeQrCode');
			},
			copy() {
				this.$copyText(this.qrUrl).then(
					() => {
						this.$toast("已复制到剪贴板!");
					},
					() => {
						this.$toast("复制失败!");
					}
				)
			},
			getNewUserNum() {
				if (this.serviceId_copy != undefined) {
					let params = {
						serviceId: this.serviceId_copy
					}
					axios({
						method: 'post',
						url: '/getNewUserNum',
						data: params
					}).then((response) => {
						if (response.data[0].code) {
							this.count = response.data[0].data;
						}
					})
				}
			},
			init() {
				let that = this;
				if (this.serviceId_copy != undefined) {
					let params = {
						serviceId: this.serviceId_copy
					}
					axios({
						method: 'post',
						url: '/getQr',
						data: params
					}).then((response) => {
						if (response.data[0].code) {
							that.qrCode =JSON.parse(response.data[0].data) ;
							if (that.qrCode!=''){
								axios({
									method: 'post',
									url: '/getFirstHost',
									data: {}
								}).then((res) => {
									if (res.data[0].code){
										let host =JSON.parse(res.data[0].data) ;
										that.qrUrl = host.hostName + '/#/?s=' + that.qrCode;
										qrcode.toDataURL(
											that.qrUrl,
											(err, url) => {
												if (err) {
													console.error(err);
												} else {
													that.qrCodeData = url;
												}
											});
									}else{
										that.qrCodeData = '';
										that.qrUrl='';
										that.msg='无可用域名';
									}
								});
							}else{
								that.qrCodeData = '';
								that.qrUrl='';
								that.msg='无有效二维码';
							}
						}else{
							that.qrCodeData = '';
							that.qrUrl='';
							that.msg='无有效二维码';
						}
					})
				}
			},
			disableQr() {
				let that = this;
				if (this.serviceId_copy != undefined) {
					let params = {
						serviceId: this.serviceId_copy
					}
					axios({
						method: 'post',
						url: '/disableQr',
						data: params
					}).then((response) => {
						if (response.data[0].code) {
							that.$toast('二维码失效成功!');
							that.init();
						}else{
							that.$toast('二维码失效失败!');
						}
					})
				}
			},
			resetQr() {
				let that = this;
				if (this.serviceId_copy != undefined) {
					let params = {
						serviceId: this.serviceId_copy
					}
					axios({
						method: 'post',
						url: '/createQr',
						data: params
					}).then((response) => {
						if (response.data[0].code) {
							that.$toast('重置二维码成功!');
							that.init();
						}else{
							that.$toast('重置二维码失败!');
						}
					})
				}
			}
		},
		watch: {
			serviceId: {
				handler() {
					this.serviceId_copy = this.serviceId;
					this.getNewUserNum();
				},
				deep: true,
				immediate: true
			}
		}
	}
</script>

<style>
	.modal2 {
		background: #fff;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		position: relative;
		width: 400px;
		height: 400px;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	.close {
		position: absolute;
		top: 10px;
		right: 20px;
		cursor: pointer;
		font-size: 24px;
	}

	.title {
		width: 100%;
		height: 50px;
	}

	.form {
		height: 50px;
		position: relative;
		display: flex;
	}

	.container {
		width: 96%;
		height: 80%;
		border: 1px solid #f1f1f1;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.qr {
		width: 220px;
		height: 220px;
	}

	.btn1 {
		width: 100px;
		height: 38px;
		margin: 20px 0;
	}
</style>