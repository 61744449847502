<template>
	<div class="infoBox">
		<!--工具栏-->
		<div class="serviceTool">
			<div v-for="(item, index) in serviceTool" :key="index" :class="[
                'service_tool',
                item.id == current_state ? 'active_tool' : '',
            ]" v-on:click="changeToolPage(item)">
				{{ item.text }}
			</div>
		</div>
		<!--用户信息-->
		<div v-show="current_state == 1" class="infoContent">
			<div class="messageDiv">
				<label>用户ID：</label>
				<span>{{ user.userId }}</span>
			</div>
			<div class="messageDiv">
				<label>用户名：</label>
				<span>{{ user.userName }}</span>
			</div>
			<div class="messageDiv">
				<label>房间号：</label>
				<span>{{ user.accessId }}</span>
			</div>
			<div class="messageDiv">
				<label>IP地址：</label>
				<span>{{ user.ip }}</span>
			</div>
			<div class="messageDiv">
				<label>登录地区：</label>
				<span>{{ user.location }}</span>
			</div>
			<div class="messageDiv">
				<label>注册时间：</label>
				<span>{{ user.userCreateTime }}</span>
			</div>
			<div class="messageDiv">
				<label>用户状态：</label>
				<span>{{user.userStateName}}</span>
			</div>
			<div class="messageDiv">
				<label>上线时间：</label>
				<span>{{ user.joinTime }}</span>
			</div>
			<div class="messageDiv">
				<label>下线时间：</label>
				<span>{{ user.leaveTime }}</span>
			</div>
			<div class="messageDiv">
				<label>终端平台：</label>
				<span>{{ user.plat }}</span>
			</div>			
			<!-- <div>
                <label>用户状态：</label>
                <span>【{{ user.data.isProhibit}}】</span>
            </div> -->
		</div>
		<!--快捷回复-->
		<div v-show="current_state == 2" class="infoContent" style="display: block">
			<template :key="index" v-for="(item, index) in fastReplay">
				<div class="messageDiv" v-on:click="selectReplay(item.content)">
					<span v-html="item.content"></span>
				</div>
			</template>
		</div>
	</div>

</template>

<script>
	import axios from 'axios';
	export default {
		name: 'ServiceRightPage',
		props: {
			user: Object
		},
		mounted() {
			this.service = Object.assign({}, JSON.parse(localStorage.getItem('serviceData')));
			this.getConfList(this.service.serviceId, 2, 1);
		},
		methods: {
			getConfList(serviceId, kind, page) {
				let params = {
					serviceId: serviceId,
					kind: kind,
					page: page
				}
				axios({
					method: 'post',
					url: '/getConfList',
					data: params
				}).then((response) => {
					if (response.data[0].code) {
						this.fastReplay = JSON.parse(response.data[0].data);
						if (JSON.parse(response.data[0].data).length == 10) {
							this.fastReplyPages = this.fastReplyPages + 1
						}
					} else {
						this.$toast("获取失败")
					}
				})
			},

			selectReplay(text) {
				this.$emit('submit', text);
			},

			sendMessage(data, type) {
				this.$emit('sendMessage', data, type);
			},
			//切换客服工具
			changeToolPage(serviceTool) {
				this.serviceTool.map((a) => {
					if (a.id == serviceTool.id) {
						a.state = true;
						this.current_state = a.id;
					} else {
						a.state = false;
					}
				});
			},
			//切换快捷回复
			selectEasy(id) {
				if (id == this.currentEasy) {
					this.currentEasy = 0;
				} else {
					this.currentEasy = id;
				}
			},

		},
		data() {
			return {
				current_state: 1,
				currentEasy: 0,
				serviceTool: [{
						id: 1,
						text: "客户信息",
						state: true,
					},
					{
						id: 2,
						text: "快捷回复",
						state: false,
					}
				],
				service: {},
				answerPages: 1,
				answerCurrPage: 1,
				answerList:[],
				fastReplyPages: 1,
				fastReplyCurrPage: 1,
				fastReplay: [],

			}
		},
	}
</script>
<style scoped>
	@import url("../assets/css/ServiceRightPage.css");
</style>