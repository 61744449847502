import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import config from './config'
import VueClipBoard from 'vue-clipboard2'

if (config.environment == 'dev') {
	axios.defaults.baseURL = '/api' //设置默认请求地址
} else if (config.environment == 'build') {
	config.apiUrl = 'https://api.' + window.location.hostname
	axios.defaults.baseURL = config.apiUrl //设置默认请求地址
}

/* axios.defaults.headers = {
	'content-type': 'application/json',
	'Authorization': 'Bearer ' + config.chatGptKey
}
 */

//按需引入vant
import 'vant/lib/index.css'
import {
	Button
} from 'vant'
import {
	Switch
} from 'vant'
import {
	Icon
} from 'vant'
import {
	NoticeBar
} from 'vant'
import {
	Toast
} from "vant"
import {
	Popover
} from "vant"
import {
	Rate
} from "vant"
import {
	Popup
} from "vant"
import {
	Slider
} from "vant"
import {
	Pagination
} from 'vant';
import {
	Sidebar,
	SidebarItem
} from 'vant';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

/**
 * 路由拦截
 * to前往的路径，from要离开的路径，拦截之后要去的路径
 */

router.beforeEach((to, from, next) => {
	window.document.title = to.meta.title == undefined ? '客服系统' : to.meta.title;
	let params = {
		token: ''
	}
	//获取token
	try {
		params.token = localStorage.getItem('token');
	} catch (e) {
		params.token = '';
	}

	//客服页面拦截，没登录就返回登录界面
	if (to.path == "/customerService") {
		//token时间及正确性验证
		axios({
			method: 'post',
			url: '/verificationToken',
			data: params
		}).then((response) => {
			if (response.data[0].code) {
				next();
				return;
			} else {
				next('/customerServiceLogin');
				return;
			}
		})
	}
	//用户聊天页面拦截
	//判断数据格式是否非空，是否正确，如果此处数据被伪造那也没关系~
	else if (to.path == "/customerChat") {
		var json = store.state.userData;
		if (store.state.userData != {} && typeof (json) == "object" && Object.prototype.toString.call(json)
			.toLowerCase() == "[object object]" && !json.length) {
			next();
			return;
		} else {
			next('/');
			return;
		}
	} else if (to.path.includes('/admin')) {
		console.log(to.path)
		if (to.path.trim() == "/admin/login") {
			//|| to.path.trim()=="/admin/main" || to.path.trim()=="/admin/home" || to.path.trim()=="/admin/modifyPwd
			console.log('pass')
			next();
			return;
		} else {
			if (JSON.parse(localStorage.getItem('staffData')) != null && JSON.parse(localStorage.getItem(
				'staffData')) != undefined) {
				next();
				return;
			} else {
				console.log('gone')
				next('/admin/login');
				return;
			}

		}
	} else {
		next();
	}

})

createApp(App).use(store).use(router).use(Button).use(Switch).use(Popup).use(Slider).use(Pagination).use(Icon).use(
	Toast).use(NoticeBar).use(Popover).use(Rate).use(Sidebar).use(SidebarItem).use(ElementPlus).use(VueClipBoard)
	.mount('#app');