<template>
	<el-row>
		<el-col :span="6">
			<el-statistic title="当天接待次数" :value="totalCount" />
		</el-col>
		<el-col :span="6">
			<el-statistic title="当天接待客户数" :value="custCount" />
		</el-col>
		<el-col :span="6">
			<el-statistic title="当天最长接待时长" :value="maxTime" />
		</el-col>
		<el-col :span="6">
			<el-statistic title="当天最短接待时长" :value="minTime" />
		</el-col>
	</el-row>
</template>

<script>
import axios from 'axios';
	export default {
		components: {
			
		},
		data() {
			return {
				totalCount:0,
				custCount:0,
				maxTime:0,
				minTime:0
			}
		},
		mounted(){
			this.getStatics();
		},
		methods: {
			getStatics(){
				axios({
					method: 'post',
					url: '/getStatics',
					data: {}
				}).then((response) => {
					if (response.data[0].code) {
						this.totalCount=response.data[0].data.totalCount;
						this.custCount=response.data[0].data.custCount;
						this.maxTime=response.data[0].data.maxTime;
						this.minTime=response.data[0].data.minTime;
					}
				})
			}
		},
	}
</script>

<style scoped>
	.el-col {
		text-align: center;
	}
</style>