<template>
	<div class="loginContainer">
		<div class="main">
			<h1 class="title">客服登录</h1>
			<el-form style="margin: 20px 0 30px 0;">
				<el-form-item label="登录卡密">
					<el-input v-model="login.servicePassword" placeholder="请输入登录卡密" clearable></el-input>
				</el-form-item>
			</el-form>
			<div class="btnDiv">
				<el-button type="primary" v-on:click="clear()">清  空</el-button>
				<el-button type="success" v-on:click="serviceLogin">登  录</el-button>
			</div>
		</div>
	</div>
</template>


<script>
	import JSEncrypt from 'jsencrypt';
	let encryptor = new JSEncrypt();
	export default {
		data() {
			return {
				socket: this.$store.state.serviceSocket,
				publicKey: '',
				login: {
					servicePassword: ''
				}
			}
		},

		mounted() {

			this.initialization();

			//接收公钥
			this.socket.on("returnPublicKey", (data) => {
				this.publicKey = JSON.stringify(data[0].data).replace(/\\r|\\n/g, '');
				encryptor.setPublicKey(this.publicKey);
			});

			//接收登录返回
			this.socket.on("loginReturn", (data) => {
				//数据存储
				//存储到localStorage
				localStorage.setItem('token', data[0].token[0].data)
				//客服数据存储到localStorage
				localStorage.setItem('serviceData', data[0].data)
				//页面跳转
				this.$router.push({
					path: '/customerService',
					replace: true
				})
			});

			//错误失败处理
			this.socket.on("error", (data) => {
				this.$toast(data[0].message)

			});

		},
		methods: {

			initialization() {

				//初始化，从服务端拿公钥
				this.socket.emit("getPublicKey");
			},

			//登录
			serviceLogin() {
				if (this.login.servicePassword == null) {
					this.$toast("卡密不能为空!");
				} else {
					//数据加密
					this.login.servicePassword = encryptor.encrypt(this.login.servicePassword);
					this.socket.emit("serviceLogin", this.login);
					this.clear();
				}
			},

			//清空密码账号输入
			clear() {
				this.login.servicePassword = null;
			},

		}

	}
</script>

<style>
	*,
	body,
	html {
		margin: 0;
		padding: 0
	}

	.loginContainer {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		background: #fff;
		cursor: pointer;
	}

	.loginContainer .main {
		width: 400px;
		height: 200px;
		margin-top: 200px;
		border: 1px solid #f0f0f0;
		box-shadow: darkgrey 10px 10px 30px 5px ;
	}
</style>