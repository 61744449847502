<template>
	<el-row class="tac">
		<el-col>
			<el-menu default-active="1" class="el-menu-vertical-demo" @select="handleSelect">
				<el-sub-menu index="1">
					<template #title>
						<el-icon>
							<Setting />
						</el-icon>
						<span>系统管理</span>
					</template>
					<el-menu-item index="1"><el-icon>
							<Avatar />
						</el-icon>用户管理
					</el-menu-item>
					<el-menu-item index="4"><el-icon>
							<Edit />
						</el-icon>域名管理
					</el-menu-item>
					<el-menu-item index="5"><el-icon>
							<SwitchButton />
						</el-icon>工作周期
					</el-menu-item>
				</el-sub-menu>
				<el-sub-menu index="2">
					<template #title>
						<el-icon>
							<document />
						</el-icon>
						<span>卡密管理</span>
					</template>
					<el-menu-item index="2"><el-icon>
							<Wallet />
						</el-icon>卡密生成</el-menu-item>
					<el-menu-item index="3"><el-icon>
							<DocumentCopy />
						</el-icon>批量复制</el-menu-item>
				</el-sub-menu>
				<!-- <el-sub-menu index="3">
					<template #title>
						<el-icon>
							<setting />
						</el-icon>
						<span>系统管理</span>
					</template>
					<el-menu-item index="4"><el-icon>
							<Edit />
						</el-icon>修改密码</el-menu-item>
					<el-menu-item index="5"><el-icon>
							<SwitchButton />
						</el-icon>退出系统</el-menu-item>
				</el-sub-menu> -->
			</el-menu>
		</el-col>

	</el-row>
</template>

<script>
	import {
		Document,
		Avatar,
		Setting,
		SwitchButton,
		Wallet,
		DocumentCopy,
		Edit
	} from '@element-plus/icons-vue';
	export default {
		components: {
			Document,
			Avatar,
			Setting,
			SwitchButton,
			Wallet,
			DocumentCopy,
			Edit
		},
		methods: {
			handleSelect(key) {
				var json = {}
				switch (key) {
					case "1":
						json = {
							label: '用户管理',
							url: '/#/admin/staffList',
							closable: true
						}
						this.$emit('menuClick', json);
						break;
					case "2":
						json = {
							label: '卡密生成',
							url: '/#/admin/serviceList',
							closable: true
						}
						this.$emit('menuClick', json);
						break;
					case "3":
						json = {
							label: '批量复制',
							url: '/#/batchConf',
							closable: true
						}
						this.$emit('menuClick', json);
						break;
					case "4":
						json = {
							label: '域名管理',
							url: '/#/admin/hostList',
							closable: true
						}
						this.$emit('menuClick', json);
						break;
					case "5":
						json = {
							label: '工作周期',
							url: '/#/admin/workTimes',
							closable: true
						}
						this.$emit('menuClick', json);
						break;
				}

			}
		}
	}
</script>