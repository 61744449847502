<template>
	<div class="main">
		<span class="goHome" @click="goHome">&lt; 返 回</span>
		<h2 class="title">用户新增查询</h2>
		<el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="servicePassword"
			style="width: 100%;"></el-input>
		<el-button type="success" v-on:click="statNewUserNum()" style="margin: 20px 0;width: 100%;">查 询</el-button>
		<div class="tab">
			<BaseTable :config="config" :data="tableData">
				<!-- :style="{width:'100%',height:'100%'}" -->
			</BaseTable>
		</div>
	</div>
</template>

<script>
	import BaseTable from '@/components/BaseTable.vue';
	import axios from 'axios';
	import moment from 'moment';
	export default {
		components: {
			BaseTable
		},
		data() {
			return {
				pagination: {
					currentPage: 1,
					pageSize: 10,
				},
				servicePassword: '',
				config: [],
				tableData: []
			}
		},
		mounted() {
			axios({
				method: 'post',
				url: '/getLastWeekDay',
				data: {}
			}).then((response) => {
				if (response.data[0].code) {
					let data = JSON.parse(response.data[0].data);
					var arr = [];
					arr.push({
						prop: 'servicePassword',
						label: '卡密'
					});
					for (var i = 0; i < data.length; i++) {
						arr.push({
							prop: 'num'+String(i),
							label: moment(data[i].nday).format('YYYY-MM-DD'),
						});
					}
					this.config = arr;
				} else {
					this.$toast("获取信息失败")
				}
			})
		},
		methods: {
			statNewUserNum() {
				if (this.servicePassword == '' || this.servicePassword == null || this.servicePassword == undefined) {
					this.$toast("卡密不能为空!");
					return;
				}
				
				let params = {
					servicePwd: this.servicePassword.split("\n")
				}
				axios({
					method: 'post',
					url: '/statNewUserNum',
					data: params
				}).then((response) => {
					if (response.data[0].code) {
						let data = JSON.parse(response.data[0].data);
						console.log(data)
						this.tableData = data;
					} else {
						this.$toast("获取信息失败")
					}
				})
			},
			goHome() {
				this.$router.push({
					path: '/fansWork',
					replace: true
				})
			}
		}
	}
</script>

<style>
	.goHome {
		position: absolute;
		top: 10px;
		left: 40px;
		cursor: pointer;
		font-size: 14px;
	}

	.tab {
		width: 100%;
		height: 800px;
		border: 1px solid #f1f1f1;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.main {
		padding: 20px 40px 10px 40px;
	}

	.btn {
		width: 100%;
		height: 38px;
		margin: 20px 0;
	}

	.title {
		text-align: center;
	}
</style>