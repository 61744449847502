<template>
	<div class="container">
		<div class="main">
			<h1 class="title">粉商工作台</h1>
			<el-form :inline="true" class="demo-form-inline">
				<el-form-item label="查询卡密">
					<el-input v-model="servicePassword" placeholder="请输入查询卡密" style="width:400px"></el-input>
				</el-form-item>
			</el-form>
			<div class="btnDiv">
				<el-button type="primary" @click="openQr">获取二维码</el-button>
				<el-button type="success" @click="openUserList">用户列表查询</el-button>
				<el-button type="warning" @click="openFansStat">进粉统计查询</el-button>
			</div>
		</div>
	</div>
	<QrCode v-if="showQrCode" @closeQrCode="closeQrCode" :serviceId="serviceId"></QrCode>
</template>


<script>
	import JSEncrypt from 'jsencrypt';
	import QrCode from '@/components/QrCode.vue';
	let encryptor = new JSEncrypt();
	export default {
		components: {
			QrCode
		},

		data() {
			return {
				socket: this.$store.state.serviceSocket,
				publicKey: '',
				servicePassword: '',
				serviceId: '',
				showQrCode: false
			}
		},

		mounted() {

			this.initialization();

			//接收公钥
			this.socket.on("returnPublicKey", (data) => {
				this.publicKey = JSON.stringify(data[0].data).replace(/\\r|\\n/g, '');
				encryptor.setPublicKey(this.publicKey);
			});

			//接收登录返回
			this.socket.on("fansWorkLoginReturn", (data) => {
				//数据存储
				//存储到localStorage
				localStorage.setItem('token', data[0].token[0].data)
				//客服数据存储到localStorage
				localStorage.setItem('fansWorkData', data[0].data)

				this.serviceId = JSON.parse(data[0].data).serviceId;
				this.showQrCode = true;
			});

			//错误失败处理
			this.socket.on("error", (data) => {
				this.$toast(data[0].message);
			});

		},
		methods: {

			initialization() {

				//初始化，从服务端拿公钥
				this.socket.emit("getPublicKey");
			},

			openQr() {
				if (this.servicePassword == '' || this.servicePassword == null || this.servicePassword == undefined) {
					this.$toast("卡密不能为空!");
				} else {
					//数据加密
					let login = {
						servicePassword: encryptor.encrypt(this.servicePassword)
					}
					this.socket.emit("fansWorkLogin", login);
				}
			},

			openUserList() {
				this.$router.push({
					path: '/newUserStat',
					replace: true
				})
			},
			
			openFansStat() {
				this.$router.push({
					path: '/fansStat',
					replace: true
				})
			},

			closeQrCode() {
				this.showQrCode = false;
			},

			//清空密码账号输入
			clear() {
				this.servicePassword = null;
			},

		}

	}
</script>

<style>
	.container .main {
		width: 500px;
		height: 300px;
	}
</style>