<template>
	<div class="modal-background1">
		<div class="modal1">
			<h2 class="title">工作周期</h2>
			<el-form size="default" label-width="90px">
				<el-row :gutter="35">
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="星  期">
							<el-input v-model="weekdayName" disabled placeholder="请输入星期" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
						<el-form-item label="周期">
							<el-input v-model="worktimes" :rows="8" type="textarea" placeholder="周期信息"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
						<el-form-item>
							格式：一行一个时间周期，如：09:30-11:30
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span class="dialog-footer">
				<el-button @click="onCancel" size="default">取 消</el-button>
				<el-button type="primary" @click="onSubmit" size="default">保 存</el-button>
			</span>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus';
	import axios from 'axios';

	export default {
		components: {

		},
		props: {
			info: Object
		},
		data() {
			return {
				staff: {},
				weekdayId:0,
				weekdayName:'',
				worktimes: '',
				dialog: {
					isShowDialog: false,
					submitTxt: '',
				},
			}
		},
		mounted() {
			let info = this.info;
			this.weekdayId = info.weekdayId;
			this.weekdayName = info.weekdayName;
			let test='';
			if (info.workTimes!=null){
				test=info.workTimes.replaceAll(',', '\n');
			}
			
			this.worktimes = test;
		},
		methods: {
			onCancel() {
				this.closeDialog();
			},
			// 关闭弹窗
			closeDialog() {
				this.$emit('closeDlg');
			},
			// 取消
			onCance() {
				this.closeDialog();
			},
			// 提交
			onSubmit() {
				let that = this;
				let worktimes = this.worktimes.split("\n"); //转成数组
				let temp=[];
				for(var i=0;i<worktimes.length;i++){
					let idx=worktimes[i].indexOf('-');
					temp.push({
						beginTime:worktimes[i].substr(0,idx),
						endTime:worktimes[i].substr(idx+1,worktimes[i].length-idx)
					});
				}
				
				let data = {
					weekday: this.weekdayId,
					worktimes: temp
				}
				axios({
						url: '/saveWorkTime',
						method: 'post',
						data: data
					}).then(function(res) {
						if (res.data[0].code > 0) {
							ElMessage.success('保存成功！');
							that.closeDialog();
						} else {
							ElMessage.warning('保存失败！');
						}
					})
					.catch(() => {});
			}
		}
	}
</script>