<template>
	<div class="system-user-container layout-padding">
			<div class="system-user-search mb15">
				<el-form :model="actionForm" size="default" label-width="40px" >
					<el-row>
						<el-input size="default" v-model="actionForm.card" placeholder="请输入卡密" style="max-width: 300px" clearable></el-input>
						<el-button size="default" type="primary" style="margin-left: 10px;"  @click="getList">
							<el-icon>
								<ele-Search />
							</el-icon>
							查  询
						</el-button>
						<el-button size="default" type="success" class="ml10" @click="onAdd()">
							<el-icon>
								<ele-FolderAdd />
							</el-icon>
							新增卡密
						</el-button>
						<el-button size="default" type="success" class="ml10" @click="onBatchSave()">
							<el-icon>
								<ele-FolderAdd />
							</el-icon>
							批量开卡
						</el-button>
					</el-row>
				</el-form>
			</div>
			<el-table :data="tableData.data" v-loading="tableData.loading" style="width: 100%;height: 750px;">
				<el-table-column type="index" label="序号" width="60" />
				<el-table-column prop="servicePassword" label="卡密" width="300" show-overflow-tooltip></el-table-column>
				<el-table-column prop="serviceName" label="客服名" width="150" show-overflow-tooltip></el-table-column>
				<el-table-column prop="createStaffName" label="创建人" width="150" show-overflow-tooltip></el-table-column>
				<el-table-column prop="createTime" label="生成时间" width="200" show-overflow-tooltip></el-table-column>
				<el-table-column prop="activeTime" label="激活时间" width="200" show-overflow-tooltip></el-table-column>
				<el-table-column prop="expireTime" label="到期时间" width="200" show-overflow-tooltip></el-table-column>
				<el-table-column prop="state" label="状态" width="100" show-overflow-tooltip>
					<template #default="scope">
						<el-tag type="info" v-if="scope.row.state==1">{{scope.row.stateName}}</el-tag>
						<el-tag type="success" v-else-if="scope.row.state==2">{{scope.row.stateName}}</el-tag>
						<el-tag type="warning" v-else-if="scope.row.state==3">{{scope.row.stateName}}</el-tag>
						<el-tag type="danger" v-else>{{scope.row.stateName}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="action" label="操作" width="160" show-overflow-tooltip>
					<template #default="scope">
						<el-button type="primary"
							@click="onEdit(scope.row)">修改</el-button>
						<el-button type="success"
							@click="onDel(scope.row)">作废</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange" class="mt15"
				:pager-count="5" :page-sizes="[10, 20, 30]" v-model:current-page="tableData.param.pageNum"
				background v-model:page-size="tableData.param.pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="tableData.total">
			</el-pagination>
	
		<serviceDlg v-if="showServiceDlg" @closeServiceDlg="closeServiceDlg" :info="selectInfo"  />
		<batchDlg v-if="showBatchDlg" @closeBatchDlg="closeBatchDlg" :info="selectInfo"  />
	</div>
</template>

<script>
	import {  ElMessageBox,ElMessage } from 'element-plus';
	import moment from 'moment';
	import axios from 'axios';
	import serviceDlg from './serviceDlg.vue';
	import batchDlg from './batchDlg.vue';
	
	export default {
		components: {
			serviceDlg,
			batchDlg
		},
		data() {
			return {
				showServiceDlg:false,
				showBatchDlg:false,
				selectInfo:{},
				actionForm: {
					card: '',
				},
				tableData: {
					data: [],
					total: 0,
					loading: false,
					param: {
						pageNum: 1,
						pageSize: 10,
					},
				},
			}
		},
		mounted() {
			this.getList();
		},
		methods: {
			closeServiceDlg() {
				this.showServiceDlg = false;
				this.getList();
			},
			closeBatchDlg() {
				this.showBatchDlg = false;
				this.getList();
			},
			getList() {
				this.tableData.loading = true;
				let that=this;
				let data={
						staffId:localStorage.getItem('staffData').Staff_ID,
						cardNo: this.actionForm.card.trim(),
						pageNum: this.tableData.param.pageNum,
						pageSize: this.tableData.param.pageSize
					}
				axios({
					url: '/getCardList',
					method: 'post',
					data: data
				}).then(function (res) {
					if (res.data[0].code >= 0) {
						let resData=JSON.parse(res.data[0].data) ;
						console.log(resData)
						for (var i = 0; i < resData.data.length; i++) {
							resData.data[i].createTime=resData.data[i].createTime==null?'':moment(resData.data[i].createTime).format('YYYY-MM-DD HH:mm:ss');
							resData.data[i].activeTime=resData.data[i].activeTime==null?'':moment(resData.data[i].activeTime).format('YYYY-MM-DD HH:mm:ss');
							resData.data[i].expireTime=resData.data[i].expireTime==null?'':moment(resData.data[i].expireTime).format('YYYY-MM-DD HH:mm:ss');
						}
						that.tableData.data = resData.data;
						that.tableData.total = resData.total;
					} else {
						ElMessage.warning('获取列表失败！');
					}
					that.tableData.loading = false;
				})
					.catch((e) => { 
						console.log(e)
						ElMessage.error('获取列表失败！');
						that.tableData.loading = false;
					});
			},
			
			// 打开新增弹窗
			onAdd() {
				this.selectInfo = null;
				this.showServiceDlg = true;
			},
			
			onBatchSave ()  {
				this.showBatchDlg = true;
			},
			
			// 打开修改弹窗
			onEdit (row) {
				this.selectInfo = row;
				this.showServiceDlg = true;
			},
			// 删除用户
			onDel (row) {
				let that=this;
				//console.log('staffId=' + row.staffId);
				ElMessageBox.confirm(`此操作将卡密“${row.id}”作废，是否继续?`, '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(() => {
						axios({
							url: '/delCard',
							method: 'post',
							data: { id: row.id }
						}).then(function (res) {
							if (res.data[0].code > 0) {
								that.getList();
								ElMessage.success('删除成功！');
							} else {
								ElMessage.warning('删除失败！');
							}
						})
							.catch(() => { });
					});
			},
			
			// 分页改变
			onHandleSizeChange(val )  {
				this.tableData.param.pageSize = val;
				this.getList();
			},
			// 分页改变
			onHandleCurrentChange(val) {
				this.tableData.param.pageNum = val;
				this.getList();
			}
		},
	}
</script>

<style scoped lang="scss">
	.system-user-container {
		:deep(.el-card__body) {
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow: auto;

			.el-table {
				flex: 1;
			}
		}
	}
</style>