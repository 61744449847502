<template>
	<input ref="referenceUpload" class="FileImage serviceFileImage" name="customerService" type="file" value=""
		v-on:change="sendVideo" />
</template>

<script>
	import axios from 'axios';
	import config from '../config';

	export default {
		name: 'SendVideo',
		methods: {
			sendMessage(data, type) {
				this.$emit('sendMessage', data, type);
			},
			sendVideo(e) {
				const fileObj = e.target.files[0];
				if (fileObj != null) {
					var fd = new FormData();
					fd.append("file", fileObj);
					const isJPG = /video\/\w+/.test(fileObj.type);
					const isLt2M = fileObj.size < 40*1024*1024;
					if (!isJPG) {
						this.$message.error("请选择视频文件!");
						return;
					}
					if (!isLt2M) {
						this.$message.error('上传图片大小不能超过 40MB!');
						return;
					}
					axios({
						method: 'post',
						url: '/uploadVideo',
						data: fd
					}).then((response) => {
						if (response.statusText=='OK'){
							console.log(response.data.path)
							this.sendMessage(config.apiUrl + response.data.path, 6);
						}
					})
					this.$refs.referenceUpload.value = null;
				}
			},

		},
		data() {
			return {

			}
		},
	}
</script>
<style scoped>
	@import url("../assets/css/SendImage.css");
</style>