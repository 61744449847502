<template>
	<div class="main">
		<span class="goHome" @click="goHome">&lt; 返 回</span>
		<h2 class="title">用户IP查询</h2>
		<el-form :inline="true" :model="service" class="demo-form-inline" style="display: flex;">
			<el-form-item label="卡密">
				<el-input v-model="servicePassword" placeholder="请输入卡密" style="width:300px"></el-input>
			</el-form-item>
			<el-form-item label="起始日期">
				<el-date-picker v-model="startDate" type="date" placeholder="选择日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="结束日期">
				<el-date-picker v-model="endDate" type="date" placeholder="选择日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="访客IP">
				<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="ips" style="width: 300px;">
				</el-input>
			</el-form-item>
			<el-button type="success" v-on:click="statIP()">查 询</el-button>
		</el-form>
		<el-button type="primary">用户接待查询</el-button>
		<el-button type="warning">秒进秒出：{{stay1s}}</el-button>
		<el-button type="success">登录5秒以上：{{stay5s}}</el-button>
		<el-button type="success">登录10秒以上：{{stay10s}}</el-button>
		<el-button type="danger">有效IP数：{{count}}</el-button>
		<el-button type="info">重复设备ID：{{recount}}</el-button>
		<el-button type="info">开启代理：0</el-button>
		<el-button type="info">模拟器：0</el-button>
		<el-button type="info">伪造浏览器：0</el-button>
		<el-button type="info">篡改浏览器ua：0</el-button>
		<el-button type="primary" v-on:click="exportXls()">导出Excel</el-button>
		<div style="height:20px"></div>
		<div class="container">
			<BaseTable id="stat" ref="report-table" :config="config" :data="tableData"
				:style="{width:'100%',height:'100%'}">
			</BaseTable>
		</div>
	</div>
	<AccessStat v-if="showStat" @closeStat="closeStat"></AccessStat>
</template>

<script>
	import BaseTable from '@/components/BaseTable.vue';
	import axios from 'axios';
	import moment from 'moment';
	import AccessStat from '@/components/AccessStat.vue';
	import FileSaver from "file-saver";
	import * as XLSX from 'xlsx';
	export default {
		components: {
			BaseTable,
			AccessStat
		},
		data() {
			return {
				startDate: '',
				endDate: '',
				servicePassword: '',
				ips: '',
				showStat: false,
				stay1s: 0,
				stay5s: 0,
				stay10s: 0,
				recount:0,
				count:0,
				pagination: {
					currentPage: 1,
					pageSize: 10,
				},
				service: {},
				config: [{
						prop: 'userName',
						label: '访客名称',
						width: '150'
					},
					{
						prop: 'createTime',
						label: '注册时间',
						width: '180'
					},
					{
						prop: 'joinTime',
						label: '登录时间',
						width: '180'
					},
					{
						prop: 'leaveTime',
						label: '离线时间',
						width: '180'
					},
					{
						prop: 'times',
						label: '在线时长',
						width: '100'
					},
					{
						prop: 'accessCount',
						label: '扫码次数',
						width: '100'
					},
					{
						prop: 'ip',
						label: '注册IP',
						width: '150'
					},
					{
						prop: 'accessCount',
						label: 'IP次数',
						width: '100'
					},
					{
						prop: 'location',
						label: '地理位置',
						width: '300'
					},
					{
						prop: 'plat',
						label: '系统版本',
						width: '600'
					},
				],
				tableData: []
			}
		},
		mounted() {
			this.startDate = Date();
			this.endDate = Date();
		},
		methods: {
			goHome() {
				this.$router.push({
					path: '/fansWork',
					replace: true
				})
			},
			openAccessStat() {
				this.showStat = true
			},
			closeStat() {
				this.showStat = false;
			},
			exportXls() {

				try {
					const $e = this.$refs['report-table'].$el
					let $table = $e.querySelector('.el-table__fixed')
					if (!$table) {
						$table = $e
					}

					const wb = XLSX.utils.table_to_book($table, {
						raw: true
					})
					const wbout = XLSX.write(wb, {
						bookType: 'xlsx',
						bookSST: true,
						type: 'array'
					})
					FileSaver.saveAs(
						new Blob([wbout], {
							type: 'application/octet-stream'
						}),
						`stat.xlsx`,
					)
				} catch (e) {
					if (typeof console !== 'undefined') console.error(e)
				}
			},
			statIP() {
				let ips = this.ips.split("\n"); //转成数组
				let params = {
					servicePwd: this.servicePassword,
					startTime: moment(this.startDate).format('YYYY-MM-DD') + ' 0:00:00',
					endTime: moment(this.endDate).format('YYYY-MM-DD') + ' 23:59:59',
					ips: ips.filter((item) => item)
				}
				axios({
					method: 'post',
					url: '/statIP',
					data: params
				}).then((response) => {
					if (response.data[0].code) {
						let stay1s = 0;
						let stay5s = 0;
						let stay10s = 0;
						let recount=0;
						this.tableData = JSON.parse(response.data[0].data);
						for (var i = 0; i < this.tableData.length; i++) {
							this.tableData[i].joinTime = this.tableData[i].joinTime == null ? '' : moment(this
								.tableData[i].joinTime).format('YYYY-MM-DD HH:mm:ss');
							this.tableData[i].leaveTime = this.tableData[i].leaveTime == null ? '' : moment(this
								.tableData[i].leaveTime).format('YYYY-MM-DD HH:mm:ss');
							this.tableData[i].createTime = this.tableData[i].createTime == null ? '' : moment(this
								.tableData[i].createTime).format('YYYY-MM-DD HH:mm:ss');
							if (this.tableData[i].stamps < 2) {
								stay1s++;
							}
							if (this.tableData[i].stamps > 5) {
								stay5s++;
							}
							if (this.tableData[i].stamps > 10) {
								stay10s++;
							}
							for (var j=0;j<i;j++){
								if (this.tableData[i].ip==this.tableData[j].ip){
									recount++;
									break;
								}
							}
						}
						this.stay1s = stay1s;
						this.stay5s = stay5s;
						this.stay10s = stay10s;
						this.recount=recount;
						this.count=this.tableData.length-recount;
					} else {
						this.$toast("获取信息失败")
					}
				})
			}
		}
	}
</script>