/**
 * 公共配置文件
 */
module.exports = {
    environment: "build",                      //打包部署服务器的时候，请把environment的dev换成build
    socketUrl: 'https://api.025403.shop',
    apiUrl: 'https://api.025403.shop',        //打包部署服务器的时候，请把apiUrl的localhost换成你的ip,如http://12.34.56.78:3030,不要使用localhost
    // socketUrl: "https://testapi.025403.shop",
    // apiUrl: "https://testapi.025403.shop",
    // socketUrl: "http://127.0.0.1:1936/",
    // apiUrl: "http://127.0.0.1:1936/",
}
