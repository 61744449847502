<template>
	<el-container>
		<el-header>
			<MyHeader :staffName="staff.Staff_Name"></MyHeader>
		</el-header>

		<el-container style="height:887px">
			<el-aside width="250px;">
				<MyNavbar :activeIndex="1" @menuClick="menuClick"></MyNavbar>
			</el-aside>
			<el-main style="padding: 0;">
				<el-tabs v-model="editableTabsValue" type="border-card" @tab-remove="removeTab" 
				style="border: 0;background-color: #fff;">
					<el-tab-pane v-for="(item) in editableTabs" :key="item.name" :label="item.title"
						:name="item.name" :closable="item.closable">
						<iframe :src="item.content" style="width: 100%;height: 760px" frameborder="0"></iframe>
					</el-tab-pane>
				</el-tabs>

				<!-- <el-table :data="tableData" :border="true" stripe>
					<el-table-column type="index" />
					<el-table-column prop="date" label="日期" width="140" align="left" header-align="center">
					</el-table-column>
					<el-table-column prop="name" label="姓名" width="120" header-align="center">
					</el-table-column>
					<el-table-column prop="address" label="地址" header-align="center">
					</el-table-column>
				</el-table> -->
			</el-main>
		</el-container>
	</el-container>
</template>


<script>
	//import axios from 'axios';
	import MyNavbar from '@/components/MyNavbar.vue';
	import MyHeader from '@/components/MyHeader.vue';

	export default {
		components: {
			MyNavbar,
			MyHeader
		},
		data() {
			const item = {
				date: '',
				name: '',
				address: ''
			};
			return {
				staff: {},
				editableTabsValue: '1',
				editableTabs: [{
					title: '首页',
					name: '1',
					content: '/#/admin/home',
					closable:false
				}],
				tabIndex: 1,
				tableData: Array(20).fill(item)
			}
		},

		mounted() {
			if (JSON.parse(localStorage.getItem('staffData')) != null) {
				this.staff = Object.assign({}, JSON.parse(localStorage.getItem('staffData')))
			}

		},

		methods: {
			//标签页处理
			menuClick(data) {
				console.log(data)
					//取得当前已打开的tab页
					let tabs = this.editableTabs;
					let flag = true; //表示可以新打开
					//tabs.forEach((tab, index) => {
					tabs.forEach((tab) => {
						if (tab.title === data.label) {
							//如果打开则选中
							this.editableTabsValue = tab.name;
							flag = false;
							return;
						}
					});
					if (flag) { //如果没有打开则添加
						let newTabName = ++this.tabIndex + '';
						this.editableTabs.push({
							title: data.label,
							name: newTabName,
							content: data.url,
							closable:data.closable
						});
						this.editableTabsValue = newTabName;
					}
			},
			//标签页移除
			removeTab(targetName) {
				let tabs = this.editableTabs;
				let activeName = this.editableTabsValue;
				if (activeName === targetName) {
					tabs.forEach((tab, index) => {
						if (tab.name === targetName) {
							let nextTab = tabs[index + 1] || tabs[index - 1];
							if (nextTab) {
								activeName = nextTab.name;
							}
						}
					});
				}

				this.editableTabsValue = activeName;
				this.editableTabs = tabs.filter(tab => tab.name !== targetName);
			}
		}
	}
</script>

<style>
	.el-header {
		background-color: dodgerblue;
		color: #333;
		max-height: 50px;
		line-height: 50px;
	}

	.el-aside {
		color: #333;
		background-color: aliceblue;
	}
	

</style>