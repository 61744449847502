<template>
	<div class="adminContainer">
		<div class="main">
			<h1 class="title">用户登录</h1>
			<el-form style="margin: 20px 0 30px 0;">
				<el-form-item label="用户名">
					<el-input v-model="login.staffName" placeholder="请输入用户名"  clearable></el-input>
				</el-form-item>
				<el-form-item label="密&nbsp;&nbsp;&nbsp;&nbsp;码">
					<el-input v-model="login.staffPwd" placeholder="请输入密码" show-password
						clearable></el-input>
				</el-form-item>
			</el-form>
			<div class="btnDiv">
				<el-button type="success" v-on:click="adminLogin">登 录</el-button>
			</div>
		</div>
	</div>
</template>


<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				login: {
					staffName: '',
					staffPwd: ''
				}
			}
		},

		methods: {
			//登录
			adminLogin() {
				if (this.login.staffName == null) {
					this.$toast("用户名不能为空!");
					return;
				}
				if (this.login.staffPwd == null) {
					this.$toast("密码不能为空!");
					return;
				}
				//数据加密
				let login = {
					name: this.login.staffName,
					pwd: this.login.staffPwd
				}

				axios({
					method: 'post',
					url: '/adminLogin',
					data: login
				}).then((response) => {
					if (response.data[0].code) {
						//客服数据存储到localStorage
						localStorage.setItem('staffData', response.data[0].data)
						//页面跳转
						this.$router.push({
							path: '/admin/main',
							replace: true
						})
					} else {
						this.$toast("用户名、密码错误")
					}
				})

			}

		}

	}
</script>

<style>
	*,
	body,
	html {
		margin: 0;
		padding: 0;
	}

	.adminContainer {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		background: #fff;
		cursor: pointer;
	}

	.adminContainer .main {
		width: 400px;
		height: 230px;
		margin-top: 200px;
		border: 1px solid #f0f0f0;
		box-shadow: darkgrey 10px 10px 30px 5px ;
	}
</style>