<template>
	<div class="modal-background1">
		<div class="modal1">
			<span class="close" @click="closeMe">&times;</span>
			<h2 class="title">个人信息</h2>
			<el-form :inline="true" :model="service" class="demo-form-inline">
				<el-form-item label="客服头像">
					<el-upload style="width:700px" class="avatar-uploader" :data="{id}"
						:action="url" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
						:on-error="handleAvatarError">
						<img v-if="imageUrl" :src="imageUrl" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="客服编号">
					<el-input v-model="service.id" :disabled="true" style="width:200px"></el-input>
				</el-form-item>
				<el-form-item style="width:125px" />
				<el-form-item label="客服别名">
					<el-input v-model="service.serviceName" style="width:200px"></el-input>
				</el-form-item>
				<el-form-item label="最大接待">
					<el-input-number v-model="service.serviceMax" @change="handleChange" :min="1" :max="100000"
						style="width:200px"></el-input-number>
				</el-form-item>
				<el-form-item style="width:125px" />
				<el-form-item label="创建时间">
					<el-input v-model="service.createTime" :disabled="true" style="width:200px"></el-input>
				</el-form-item>
				<el-form-item label="激活时间">
					<el-input v-model="service.activeTime" :disabled="true" style="width:200px"></el-input>
				</el-form-item>
				<el-form-item style="width:125px" />
				<el-form-item label="失效时间">
					<el-input v-model="service.expireTime" :disabled="true" style="width:200px"></el-input>
				</el-form-item>
				<el-form-item label="上线提醒">
					<el-switch style="width:200px" v-model="onlineHint" active-color="#13ce66" inactive-color="#d3d3d3"
						active-text="开" inactive-text="关">
					</el-switch>
				</el-form-item>
				<el-form-item style="width:125px" />
				<el-form-item label="下线提醒">
					<el-switch style="width:200px" v-model="offlineHint" active-color="#13ce66" inactive-color="#d3d3d3"
						active-text="开" inactive-text="关">
					</el-switch>
				</el-form-item>
			</el-form>
			<div class="btnDiv">
				<el-button type="success" @click="onConfirm" class="myBtn">确 定</el-button>
				<el-button type="success" @click="closeMe" class="myBtn">取 消</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import moment from 'moment';
	import config from '../config';
	export default {
		data() {
			return {
				url:config.apiUrl+'/uploadAvatar',
				service: {},
				imageUrl: '',
				id: '',
				onlineHint: true,
				offlineHint: true
			}
		},
		mounted() {
			this.service = Object.assign({}, JSON.parse(localStorage.getItem('serviceData')));
			this.service.createTime = moment(this.service.createTime).format('YYYY-MM-DD HH:mm:ss');
			this.service.activeTime = moment(this.service.activeTime).format('YYYY-MM-DD HH:mm:ss');
			this.service.expireTime = moment(this.service.expireTime).format('YYYY-MM-DD HH:mm:ss');
			this.id = this.service.serviceId;
			this.imageUrl = config.apiUrl + this.service.headImg;
			if (this.service.onlineHint == 1) {
				this.onlineHint = true
			} else {
				this.onlineHint = false
			}
			if (this.service.offlineHint == 1) {
				this.offlineHint = true
			} else {
				this.offlineHint = false
			}
		},
		methods: {
			closeMe() {
				this.$emit('closeProfile');
			},
			onConfirm() {
				this.service.onlineHint = this.onlineHint;
				this.service.offlineHint = this.offlineHint;
				let params = {
					serviceId: this.service.serviceId,
					serviceName: this.service.serviceName,
					serviceMax: this.service.serviceMax,
					onlineHint: this.onlineHint,
					offlineHint: this.offlineHint
				}
				axios({
					method: 'post',
					url: '/updateService',
					data: params
				}).then((response) => {
					this.closeMe();
					if (response.data[0].code) {
						this.$toast("保存成功");
						localStorage.setItem('serviceData', JSON.stringify(this.service));
					} else {
						this.$toast("保存失败")
					}
				})
			},
			handleAvatarError() {
				this.$message.error("上传头像失败");
			},
			handleAvatarSuccess(res, file) {
				this.service.headImg=res[0].data.path;
				localStorage.setItem('serviceData', JSON.stringify(this.service));
				this.imageUrl = URL.createObjectURL(file.raw);
				this.$message.success("上传头像成功");
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" || file
					.type === "image/gif";
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error("上传头像图片只能是 GIF、JPG、JPEG 或 PNG 格式!");
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	};
</script>

<style>
	.modal-background1 {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 1100;
	}

	.modal1 {
		background: #fff;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		position: relative;
		width: 800px;
		height: 550px;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	.close1 {
		position: absolute;
		top: 10px;
		right: 20px;
		cursor: pointer;
		font-size: 24px;
	}

	.btnDiv {
		font-size: 14px;
		height: 30px;
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	.myBtn {
		margin: 0 30px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>