import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
//import Home from '../views/Home.vue'
import comment from '../views/Comment.vue'
import customerChat from '../views/customerChat.vue'
import customerServiceLogin from '../views/customerServiceLogin.vue'
import customerService from '../views/customerService.vue'
import fansWork from '../views/fansWork.vue'
import newUserStat from '../views/newUserStat.vue'
import fansStat from '../views/fansStat.vue'
import stateQry from '../views/stateQry.vue'
import errPage from '../views/errPage.vue'
import batchConf from '../views/batchConf.vue'
import aLogin from '../views/admin/aLogin.vue'
import aMain from '../views/admin/aMain.vue'
import aHome from '../views/admin/aHome.vue'
import staffList from '../views/admin/staffList.vue'
import serviceList from '../views/admin/serviceList.vue'
import aBatchConf from '../views/admin/batchConf.vue'
import modifyPwd from '../views/admin/modifyPwd.vue'
import hostList from '../views/admin/hostList.vue'
import workTimes from '../views/admin/workTimes.vue'

const routes = [{
		path: '/comment',
		name: 'comment',
		component: comment,
		meta: {
			title: '客服系统-客户端',
		}
	},
	{
		path: '/',
		name: 'customerChat',
		component: customerChat,
		meta: {
			requireAuth: true, //开启权限，
			title: '客服系统-客户端'
		}
	},
	{
		path: '/customerServiceLogin',
		name: 'customerServiceLogin',
		component: customerServiceLogin,
		meta: {
			title: '客服系统-客服端',
		}
	},
	{
		path: '/customerService',
		name: 'customerService',
		component: customerService,
		meta: {
			requireAuth: true, //开启权限
			title: '客服系统-客服端'
		}
	},
	{
		path: '/fansWork',
		name: 'fansWork',
		component: fansWork,
		meta: {
			title: '客服系统-粉商工作台'
		}
	},
	{
		path: '/newUserStat',
		name: 'newUserStat',
		component: newUserStat,
		meta: {
			title: '用户新增查询'
		}
	},
	{
		path: '/fansStat',
		name: 'fansStat',
		component: fansStat,
		meta: {
			title: '进粉统计查询'
		}
	},
	{
		path: '/stateQry',
		name: 'stateQry',
		component: stateQry,
		meta: {
			title: '卡密状态查询'
		}
	},
	{
		path: '/batchConf',
		name: 'batchConf',
		component: batchConf,
		meta: {
			title: '话术批量复制'
		}
	},
	{
		path: '/errPage',
		name: 'errPage',
		component: errPage,
		meta: {
			title: '错误提示'
		}
	},
	{
		path: '/admin/login',
		name: 'aLogin',
		component: aLogin,
		meta: {
			//requireAuth: true ,//开启权限
			title: '客服系统-管理端'
		}
	},
	{
		path: '/admin/main',
		name: 'aMain',
		component: aMain,
		meta: {
			//requireAuth: true ,//开启权限
			title: '客服系统-管理端'
		}
	},
	{
		path: '/admin/home',
		name: 'aHome',
		component: aHome,
		meta: {
			title: '客服系统-管理端'
		}
	},
	{
		path: '/admin/modifyPwd',
		name: 'modifyPwd',
		component: modifyPwd,
		meta: {
			title: '客服系统-管理端'
		}
	},
	{
		path: '/admin/staffList',
		name: 'staffList',
		component: staffList,
		meta: {
			title: '客服系统-管理端'
		}
	},
	{
		path: '/admin/serviceList',
		name: 'serviceList',
		component: serviceList,
		meta: {
			title: '客服系统-管理端'
		}
	},
	{
		path: '/admin/batchConf',
		name: 'aBatchConf',
		component: aBatchConf,
		meta: {
			title: '客服系统-管理端'
		}
	},
	{
		path: '/admin/hostList',
		name: 'hostList',
		component: hostList,
		meta: {
			title: '客服系统-管理端'
		}
	},
	{
		path: '/admin/workTimes',
		name: 'workTimes',
		component: workTimes,
		meta: {
			title: '客服系统-管理端'
		}
	}
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

export default router