<template>
	<el-row>
		<el-table :data="tableData.data" v-loading="tableData.loading" style="width: 100%;height: 750px;">
			<el-table-column type="index" label="序号" width="60" />
			<el-table-column prop="weekdayName" label="周期" width="120" show-overflow-tooltip></el-table-column>
			<el-table-column prop="workTimes" label="检测时间" show-overflow-tooltip></el-table-column>
			<el-table-column label="操作" width="100">
				<template #default="scope">
					<el-button size="small" type="primary"
						@click="onOpenEdit(scope.row)">修改</el-button>
				</template>
			</el-table-column>
		</el-table>
	</el-row>
	<worktimeDlg v-if="showDlg" @closeDlg="closeDlg" :info="selectInfo" />
</template>

<script>
	import axios from 'axios';
	import worktimeDlg from './worktimeDlg.vue';
	export default {
		components: {
			worktimeDlg
		},
		data() {
			return {
				showDlg: false,
				selectInfo: {},
				tableData: {
					data: [],
					loading: false,
				}
			}
		},
		mounted() {
			this.getList();
		},
		methods: {
			getList() {
				this.tableData.loading = true;
				let that = this;
				axios({
						url: '/getWorkTimeList',
						method: 'post',
						data: {}
					}).then(function(res) {
						if (res.data[0].code) {
							let resData = JSON.parse(res.data[0].data);
							that.tableData.data = resData;
						} else {
							that.$toast('没有符合条件的数据！');
						}
						that.tableData.loading = false;
					})
					.catch((e) => {
						console.log(e)
						this.$toast('获取列表失败！');
						this.tableData.loading = false;
					});
			},

			// 打开修改用户弹窗
			onOpenEdit(row) {
				this.selectInfo = row;
				this.showDlg = true;
			},
			closeDlg() {
				this.showDlg = false;
				this.getList();
			}
			
		}
	}
</script>