<template>
	<div class="main">
		<!-- <span class="goHome" @click="goHome">&lt; 返 回</span> -->
		<h2 class="title">卡密状态查询</h2>
		<el-form :inline="true" :model="service" class="demo-form-inline" style="display: flex;">
			<el-form-item label="卡密">
				<el-input type="textarea" v-model="servicePwds" placeholder="请输入卡密，一行一个" style="width:300px"></el-input>
			</el-form-item>
			<el-button type="success" v-on:click="getServiceState()">查 询</el-button>
		</el-form>
		<div class="container">
			<BaseTable id="stat" ref="report-table" :config="config" :data="tableData"
				:style="{width:'100%',height:'100%'}">
				<template #btn="{row}">
					<el-button v-if="row.state==1 || row.state==3" type="success" v-on:click="updateState(row.serviceId,2)">激  活</el-button>
					<el-button v-if="row.state==2" type="warning" v-on:click="updateState(row.serviceId,3)">失  效</el-button>
				</template>
			</BaseTable>
		</div>
	</div>
</template>

<script>
	import BaseTable from '@/components/BaseTable.vue';
	import axios from 'axios';
	import moment from 'moment';
	export default {
		components: {
			BaseTable
		},
		data() {
			return {
				servicePwds: '',
				config: [{
						prop: 'servicePassword',
						label: '卡密',
						width: '300'
					},
					{
						prop: 'stateName',
						label: '状态',
						width: '100'
					},
					{
						prop: 'createTime',
						label: '创建时间',
						width: '180'
					},
					{
						prop: 'activeTime',
						label: '激活时间',
						width: '180'
					},
					{
						prop: 'expireTime',
						label: '失效时间',
						width: '180'
					},
					{
						prop: 'btn',
						label: '操作',
						slot: 'btn'
					}
				],
				tableData: []
			}
		},
		methods: {
			goHome() {
				this.$router.push({
					path: '/fansWork',
					replace: true
				})
			},
			getServiceState() {
				let servicePwds = this.servicePwds.split("\n"); //转成数组
				let params = {
					servicePwds: servicePwds.filter((item) => item)
				}
				axios({
					method: 'post',
					url: '/getServiceState',
					data: params
				}).then((response) => {
					if (response.data[0].code) {
						this.tableData = JSON.parse(response.data[0].data);
						for (var i = 0; i < this.tableData.length; i++) {
							this.tableData[i].createTime = this.tableData[i].createTime == null ? '' : moment(this
								.tableData[i].createTime).format('YYYY-MM-DD HH:mm:ss');
							this.tableData[i].activeTime = this.tableData[i].activeTime == null ? '' : moment(this
								.tableData[i].activeTime).format('YYYY-MM-DD HH:mm:ss');
							this.tableData[i].expireTime = this.tableData[i].expireTime == null ? '' : moment(this
								.tableData[i].expireTime).format('YYYY-MM-DD HH:mm:ss');
						}
					} else {
						this.$toast("获取信息失败")
					}
				})
			},
			updateState(serviceId,state){
				let params = {
					serviceId: serviceId,
					state:state
				}
				axios({
					method: 'post',
					url: '/updateState',
					data: params
				}).then((response) => {
					if (response.data[0].code) {
						this.$toast("状态修改成功")	
						this.getServiceState()
					} else {
						this.$toast("状态修改失败")
					}
				})
			}
		}
	}
</script>