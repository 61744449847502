<template>
	<input ref="referenceUpload" class="FileImage serviceFileImage" name="customerService" type="file" value=""
		v-on:change="sendImage" />
</template>

<script>
	import axios from 'axios';
	import config from '../config';

	export default {
		name: 'SendImage',
		methods: {
			sendMessage(data, type) {
				this.$emit('sendMessage', data, type);
			},
			sendImage(e) {
				const fileObj = e.target.files[0];
				if (fileObj != null) {
					var fd = new FormData();
					fd.append("file", fileObj);
					const isJPG = /image\/\w+/.test(fileObj.type); 
					const isLt2M = fileObj.size < 20*1024*1024;
					if (!isJPG) {
						this.$message.error("请选择图片文件!");
						return;
					}
					if (!isLt2M) {
						this.$message.error('上传图片大小不能超过 20MB!');
						return;
					}
					axios({
						method: 'post',
						url: '/uploadImg',
						data: fd
					}).then((response) => {
						if (response.statusText=='OK'){
							this.sendMessage(config.apiUrl + response.data.path, 3);
						}
					})
					this.$refs.referenceUpload.value = null;
				}
			},

		},
		data() {
			return {

			}
		},
	}
</script>
<style scoped>
	@import url("../assets/css/SendImage.css");
</style>