<template>
	<div class="Chat">
		<!--会话窗口-->
		<div class="customerChat">
			<div class="customerChatHead" :style="this.$store.state.bgColor">在线客服</div>
			<!--聊天内容-->
			<MessageWindow :messageList="messageList" class="customerChatMessage" id="customerChatWindow"
				:sendId="this.$store.state.userData.userId" :receiveId="this.$store.state.userData.receiveId"
				:isService="'false'" :serviceHead="user.serviceHead"></MessageWindow>
			<!--聊天框底部-->
			<div class="customerChatFoot">
				<div v-show="!allowSession" class="notAllowSeesion"></div>
				<div class="customerChatTool">
					<!--表情包-->
					<SendEmote v-show="EmoteShow" @sendMessage="sendMessage"></SendEmote>
					<!--工具栏-->
					<div class="customerChatToolList">
						<ul>
							<li v-on:click="EmoteShow = !EmoteShow">
								<img src="../assets/images/expression.png" />
							</li>
							<li v-on:click="EmoteShow = false" style="position: relative">
								<img src="../assets/images/imageFile.png" style="width:22px ;height:22px;" />
								<SendImage @sendMessage="sendMessage"></SendImage>
							</li>
							<li v-on:click="EmoteShow = false" style="position: relative">
								<img src="../assets/images/videoFile.png" style="width:22px ;height:22px;" />
								<SendVideo @sendMessage="sendMessage"></SendVideo>
							</li>
						</ul>
					</div>
					<!--发送内容-->
					<div style="height: calc(100% - 70px)">
						<textarea v-on:focus="EmoteShow = false" id="dope" v-model="sendData" class="customerChatText"
							:placeholder="allowSession ? '请输入会话内容' : ''" v-on:keyup.enter="enterSend"></textarea>
						<button class="customerChatButton" id="serviceSendBtn" v-on:click="sendMessage(sendData, 1)"
							:style="this.$store.state.bgColor">
							发送(s)
						</button>
					</div>

				</div>
			</div>
		</div>

	</div>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import MessageWindow from '@/components/MessageWindow.vue';
import SendEmote from '@/components/SendEmote.vue';
import SendImage from '@/components/SendImage.vue';
import SendVideo from '@/components/SendVideo.vue';
import axios from 'axios';
import moment from 'moment';

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load()

export default {
	components: {
		MessageWindow,
		SendEmote,
		SendImage,
		SendVideo
	},
	data() {
		return {
			socket: this.$store.state.socket,
			EmoteShow: false,
			sendData: '',
			allowSession: true,
			message: '',
			user: {},
			userId: '',
			messageList: [],
			socketRoom: '',
			serviceId: '',
			qrCode: ''
		}
	},
	created() {
		this.qrCode = this.$route.query.s;
		if (this.qrCode == undefined) {
			this.$router.push({
				path: '/errPage',
				replace: true
			})
		}

	},

	mounted() {
		//注册socket事件
		//连接到api
		this.socket.on("connect", () => {
			this.messageList.push({
				sendType: 5,
				sendPeople: 'notice',
				message: `1.页面初始化 连接到api.${window.location.hostname} - ${this.socket.connected}`
			});
			//等待Socket 连接后UID初始化
			this.initialization()
		})

		//连接客服成功通知
		this.socket.on("linkServiceSuccess", (data) => {
			if (data[0].code) {

				this.user = data[0].data;

				if (this.user.serviceName) {
					this.messageList.push({
						sendType: 5,
						sendPeople: 'notice',
						message: "3.连接到客服[" + this.user.serviceName + "]"
					});
					localStorage.setItem('userData', JSON.stringify(data[0].data));
					this.$store.state.userData = this.user;
					this.allowSession = true;
					this.getHello(this.user.serviceId);
				} else {
					this.$toast(
						'未获取到ServiceName，请联系客服解决'
					);
					// set timeout to redirect to home page 5seconds later
					setTimeout(() => {
						this.$router.go(0)
					}, 5000)

				}

			} else {
				this.$router.push({
					path: '/errPage',
					replace: true
				})
			}
		});
		//接收消息
		this.socket.on("reviceMessage", (data) => {
			this.message = data[0].data.message;
			let obj = {
				sendType: 5,
				sendPeople: 'notice',
				message: data[0].data.time
			}
			this.messageList.push(obj)
			obj = {
				sendType: data[0].data.sendType,
				sendPeople: 'other',
				message: data[0].data.message
			}
			this.messageList.push(obj)
			this.toBottom(128)
		});

		//错误接收
		this.socket.on("error", (data) => {
			if (data[0].type == 'qrFalse' || data[0].type == 'inBlackList') {
				this.$router.push({
					path: '/errPage?msg=' + data[0].message,
					replace: true
				})
			} else {
				this.$toast(data[0].message);
			}
		});

		//离线处理
		this.socket.on("Offline", () => {
			this.socket.close()
		});
	},
	methods: {
		initialization() {
			//获取浏览器指纹并发送初始数据
			fpPromise
				.then(fp => fp.get())
				.then(result => {
					// UID初始化
					this.userId = result.visitorId
					if (this.qrCode != undefined) {
						let params = {
							qrCode: this.qrCode,
							userId: this.userId,
							userName: "用户" + this.userId.slice(0, 6),
							plat: navigator.userAgent.toLocaleLowerCase()
						}
						this.messageList.push({
							sendType: 5,
							sendPeople: 'notice',
							message: `2. 用户${this.userId} 上线`
						});
						this.socket.emit("userOnline", params);
					}
				})

		},

		sendMessage(data, sendType) {
			//判断发送类型
			if (sendType === 1 && this.sendData.length <= 0) {
				this.$toast("内容不能为空!");
				return;
			}
			if (sendType === 2 && this.$route.path === '/customerChat') {
				this.EmoteShow = !this.EmoteShow;
			}
			//向socket发送数据请求
			this.user.message = data;
			this.user.sendType = sendType;
			this.user.sendUserType = 2;
			this.socket.emit("sendMessage", this.user);
			//将数据存入与这个用户的聊天信息列表
			let obj = {
				sendType: 5,
				sendPeople: 'notice',
				message: moment().format('YYYY-MM-DD HH:mm:ss')
			}
			this.messageList.push(obj)
			obj = {
				sendType: sendType,
				sendPeople: 'me',
				message: data
			}
			this.messageList.push(obj)
			//清空输入框
			this.sendData = '';
			//让聊天窗口回到底部
			this.toBottom(128)
		},

		//回到底部
		toBottom(time) {
			setTimeout(() => {
				let RightCont = document.getElementById("customerChatWindow");
				if (RightCont != null) {
					let scrollHeight2 = RightCont.scrollHeight;
					RightCont.scrollTop = scrollHeight2;
				}
			}, time);
			clearTimeout();
		},
		getHello(serviceId) {
			let params = {
				serviceId: serviceId,
			}
			axios({
				method: 'post',
				url: '/getHello',
				data: params
			}).then((response) => {
				this.messageList.push({
					sendType: 5,
					sendPeople: 'notice',
					message: "4.与客服打招呼" + serviceId
				});
				if (response.data[0].code) {
					let data = JSON.parse(response.data[0].data);
					let obj = {
						sendType: 1,
						sendPeople: 'other',
						message: data.content
					}
					this.messageList.push(obj);

					let service = {}
					service.serviceId = serviceId;
					service.receiveId = this.user.userId;
					service.message = data.content;
					service.sendType = 1;
					service.sendUserType = 3;
					// 提交数据到api
					this.socket.emit("sendMessage", service);
					this.getAnswerList(serviceId);
				}

			})
		},
		getAnswerList(serviceId) {
			let params = {
				serviceId: serviceId,
			}
			axios({
				method: 'post',
				url: '/getAnswerList',
				data: params
			}).then((response) => {
				this.messageList.push({
					sendType: 5,
					sendPeople: 'notice',
					message: "5.获取问答" + serviceId
				});
				if (response.data[0].code) {
					let data = JSON.parse(response.data[0].data);
					let obj = {
						sendType: 4,
						sendPeople: 'other',
						message: '您可能对以下问题感兴趣，请点击问题获取答案。',
						answerList: data
					}
					this.messageList.push(obj);

					let service = {}
					service.serviceId = serviceId;
					service.receiveId = this.user.userId;
					let dataList = '您可能对以下问题感兴趣，请点击问题获取答案。';
					for (var i = 0; i < data.length; i++) {
						dataList = dataList + '<br>' + data[i].title
					}
					service.message = dataList;
					service.sendType = 1;
					service.sendUserType = 3;
					this.socket.emit("sendMessage", service);

				}

			})
		}
	}
}
</script>

<style scoped>
@import url("../assets/css/customerChat.css");
</style>