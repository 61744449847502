<template>
	<div class="modal-background1">
		<div class="modal1">
			<h2 class="title">卡密信息</h2>
			<el-form ref="userDialogFormRef" :model="ruleForm" size="default" label-width="90px">
				<el-row :gutter="35">
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="客服名称">
							<el-input v-model="ruleForm.serviceName" placeholder="请输入客服名称" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<!-- <el-form-item label="头    像">
							<el-input v-model="state.ruleForm.avatar" type="file" accept="image/png, image/jpeg"
								placeholder="请上传头像"></el-input>
						</el-form-item> -->
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="卡 密 号">
							<el-input v-model="ruleForm.cardNo" placeholder="自动生成卡密号" clearable
								:disabled="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="有 效 期">
							<el-input-number v-model="ruleForm.expireHours" placeholder="请输入有效期(小时)"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="最大接待数">
							<el-input-number v-model="ruleForm.serviceMax" placeholder="最大接待数量"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="接待次数">
							<el-input v-model="ruleForm.serviceFrequency" placeholder="显示接待次数" clearable
								:disabled="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="创建人员">
							<el-input v-model="ruleForm.createStaffName" placeholder="自动生成创建人员" clearable
								:disabled="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="创建时间">
							<el-input v-model="ruleForm.createTime" placeholder="自动生成创建时间" clearable
								:disabled="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="激活时间">
							<el-input v-model="ruleForm.activeTime" placeholder="自动生成激活时间" clearable
								:disabled="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="失效时间">
							<el-input v-model="ruleForm.expireTime" placeholder="自动生成失效时间" clearable
								:disabled="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="卡密状态">
							<el-input v-model="ruleForm.serviceStateName" placeholder="显示卡密状态" clearable
								:disabled="true"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="default">取 消</el-button>
					<el-button type="primary" @click="onSubmit" size="default">保  存</el-button>
				</span>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus';
	import axios from 'axios';

	export default {
		components: {

		},
		props: {
			info: Object
		},
		data() {
			return {
				staff:{},
				ruleForm: {
					id: 0,
					cardNo: '',
					serviceName: '',
					expireHours: 24,
					serviceMax: 5000,
					serviceFrequency: 0,
					avatar: '',
					createStaffName: '',
					createTime: '',
					activeTime: '',
					expireTime: '',
					serviceStateName: ''
				},
				dialog: {
					isShowDialog: false,
					editMode: false,
					title: '',
					submitTxt: '',
				},
			}
		},
		mounted() {
			if (JSON.parse(localStorage.getItem('staffData')) != null) {
				this.staff = Object.assign({}, JSON.parse(localStorage.getItem('staffData')))
			} else {
				this.$router.push({
					path: '/admin/login',
					replace: true
				})
			}
			let info = this.info ;
			console.log(this.info);
			if (info!={} && info!=null) {
				console.log('1')
				this.dialog.editMode=true;
				this.ruleForm.id = info.id;
				this.ruleForm.cardNo = info.servicePassword;
				this.ruleForm.serviceName = info.serviceName;
				this.ruleForm.expireHours = info.expireHours;
				this.ruleForm.serviceMax = info.serviceMax;
				this.ruleForm.serviceFrequency = info.serviceFrequency;
				this.ruleForm.createStaffName = info.createStaffName;
				this.ruleForm.createTime = info.createTime;
				this.ruleForm.activeTime = info.activeTime;
				this.ruleForm.expireTime = info.expireTime;
				this.ruleForm.serviceStateName = info.stateName;
			} else {
				console.log('2')
				this.dialog.editMode=false;
				this.ruleForm.id = 0;
				this.ruleForm.cardNo = '';
				this.ruleForm.serviceName = '';
				this.ruleForm.expireHours = 24;
				this.ruleForm.serviceMax = 5000;
				this.ruleForm.serviceFrequency =0;
				this.ruleForm.createStaffName = '';
				this.ruleForm.createTime = '';
				this.ruleForm.activeTime = '';
				this.ruleForm.expireTime = '';
				this.ruleForm.serviceStateName = '';
			}
		},
		methods: {
			onCancel(){
				this.closeDialog();
			},
			// 关闭弹窗
			closeDialog() {
				this.$emit('closeServiceDlg');
			},
			// 取消
			onCance() {
				this.closeDialog();
			},
			// 提交
			onSubmit() {
				let that=this;
				if (this.ruleForm.serviceName.trim() == '') {
					ElMessage.error('客服名不能为空');
					return false;
				}

				let data = {
					id: this.ruleForm.id,
					name: this.ruleForm.serviceName,
					hours: this.ruleForm.expireHours,
					maxIn: this.ruleForm.serviceMax,
					avatar: this.ruleForm.avatar,
					staffId: this.staff.Staff_ID
				}
				console.log(data)
				axios({
						url: '/saveCard',
						method: 'post',
						data: data
					}).then(function(res) {
						if (res.data[0].code > 0) {
							ElMessage.success('保存成功！');
							that.closeDialog();
						} else {
							ElMessage.warning('保存失败！');
						}
					})
					.catch(() => {});
			}
		}
	}
</script>