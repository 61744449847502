<template>
	<div class="modal-background">
		<div class="modal">
			<span class="close" @click="closeMe">&times;</span>
			<h2 class="title">{{kind.text}}</h2>
			<div class="form">
				<el-button type="success" v-on:click="addConf()">新 增</el-button>
				<el-button type="success" v-on:click="getConfList()">刷 新</el-button>
			</div>
			<div class="container">
				<BaseTable :config="config" :data="tableData" :style="{width:'100%',height:'100%'}">
					<template #btn="{row}">
						<el-button type="success" v-on:click="modify(row)">修 改</el-button>
						<el-button type="success" v-on:click="del(row.confId)">删 除</el-button>
					</template>
				</BaseTable>
				<!-- <el-pagination background :total="tableData.length" v-model:current-page="pagination.currentPage"
					v-model:page-size="pagination.pageSize" @current-change="getConfList" @size-change="getConfList"
					class="pagination">
				</el-pagination> -->
			</div>
		</div>
	</div>
	<ConfInfo v-if="showDlg" @closeDlg="closeDlg" :info="selectInfo" :serviceId="service.serviceId" :kind="kind"></ConfInfo>
</template>

<script>
	import BaseTable from './BaseTable.vue';
	import axios from 'axios';
	import ConfInfo from './ConfInfo.vue';
	export default {
		props: {
			kind: Object
		},
		components: {
			BaseTable,
			ConfInfo
		},
		data() {
			return {
				showDlg: false,
				selectInfo: {},
				pagination: {
					currentPage: 1,
					pageSize: 10,
				},
				service: {},
				config: [{
						prop: 'title',
						label: '标题',
						width: '200'
					},
					{
						prop: 'content',
						label: '内容',
					},
					{
						prop: 'createTime',
						label: '创建时间',
						width: '160'
					},
					{
						prop: 'btn',
						label: '操作',
						slot: 'btn',
						width: '180'
					},
				],
				tableData: []
			}
		},
		mounted() {
			this.service = Object.assign({}, JSON.parse(localStorage.getItem('serviceData')));
			this.getConfList();
		},
		methods: {
			closeMe() {
				this.$emit('closeInfo');
			},
			closeDlg() {
				this.showDlg = false;
				this.getConfList();
			},
			getConfList() {
				let params = {
					serviceId: this.service.serviceId,
					kind: this.kind.id,
					page: this.pagination.currentPage
				}
				axios({
					method: 'post',
					url: '/getConfList',
					data: params
				}).then((response) => {
					if (response.data[0].code) {
						this.tableData = JSON.parse(response.data[0].data);
						if (JSON.parse(response.data[0].data).length == 10) {
							this.pagination.pageSize = this.pagination.pageSize + 1
						}
					} else {
						this.$toast("获取信息失败")
					}
				})
			},
			modify(row) {
				this.selectInfo = row;
				this.showDlg = true;
			},
			del(id) {
				let params = {
					confId: id
				}
				axios({
					method: 'post',
					url: '/delConf',
					data: params
				}).then((response) => {
					this.getConfList();
					if (response.data[0].code) {
						this.$toast("删除成功")
					} else {
						this.$toast("删除失败")
					}
				})
			},
			addConf() {
				this.selectInfo = null;
				this.showDlg = true;
			}
		}
	}
</script>

<style>
	.modal-background {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 1000;
	}

	.modal {
		background: #fff;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		position: relative;
		width: 80%;
		height: 80%;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	.close {
		position: absolute;
		top: 10px;
		right: 20px;
		cursor: pointer;
		font-size: 24px;
	}

	.title {
		width: 100%;
		height: 50px;
	}

	.form {
		height: 50px;
		position: relative;
		display: flex;
	}

	.container {
		width: 96%;
		height: 80%;
		border: 1px solid #f1f1f1;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.pagination {
		padding: 10px;
	}
</style>