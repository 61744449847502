<template>
	<div class="modal-background">
		<div class="modal">
			<span class="close" @click="closeMe">&times;</span>
			<h2 class="title">卡密查询</h2>
			<el-form :inline="true" :model="service" class="demo-form-inline" style="display: flex;">
				<el-form-item label="卡密">
					<el-input v-model="servicePwd" style="width:300px" placeholder="请输入卡密"></el-input>
				</el-form-item>
				<el-button type="success" v-on:click="getServiceList()">查 询</el-button>
			</el-form>

			<div class="container">
				<BaseTable :config="config" :data="tableData" :style="{width:'100%',height:'100%'}">
				</BaseTable>
				<!-- <el-pagination background :total="tableData.length" v-model:current-page="pagination.currentPage"
					v-model:page-size="pagination.pageSize" @current-change="getServiceList" @size-change="getServiceList"
					class="pagination">
				</el-pagination> -->
			</div>
		</div>
	</div>
</template>

<script>
	import BaseTable from './BaseTable.vue';
	import moment from 'moment';
	import axios from 'axios';
	export default {
		components: {
			BaseTable
		},
		data() {
			return {
				pagination: {
					currentPage: 1,
					pageSize: 10,
				},
				servicePwd: '',
				config: [{
						prop: 'serviceName',
						label: '客服名',
						width: '120'
					},
					{
						prop: 'stateName',
						label: '状态',
						width: '120'
					},
					{
						prop: 'createTime',
						label: '创建时间',
						width: '180'
					},
					{
						prop: 'activeTime',
						label: '激活时间',
						width: '180'
					},
					{
						prop: 'expireTime',
						label: '失效时间',
						width: '180'
					},
					{
						prop: 'serviceMax',
						label: '最大并行数'
					}
				],
				tableData: []
			}
		},
		methods: {
			closeMe() {
				this.$emit('closeCardQry');
			},
			getServiceList() {
				if (this.servicePwd == '') {
					this.$toast("请输入卡密")
					return;
				}
				let params = {
					servicePwd: this.servicePwd,
					state: 0,
					page: this.pagination.currentPage
				}
				axios({
					method: 'post',
					url: '/getServiceList',
					data: params
				}).then((response) => {
					if (response.data[0].code) {
						this.tableData = JSON.parse(response.data[0].data);
						for (var i = 0; i < this.tableData.length; i++) {
							this.tableData[i].createTime = this.tableData[i].createTime == null ? '' : moment(this
								.tableData[i].createTime).format('YYYY-MM-DD HH:mm:ss');
							this.tableData[i].activeTime = this.tableData[i].activeTime == null ? '' : moment(this
								.tableData[i].activeTime).format('YYYY-MM-DD HH:mm:ss');
							this.tableData[i].expireTime = this.tableData[i].expireTime == null ? '' : moment(this
								.tableData[i].expireTime).format('YYYY-MM-DD HH:mm:ss');
						}
						if (JSON.parse(response.data[0].data).length == 10) {
							this.pagination.pageSize = this.pagination.pageSize + 1
						}
					} else {
						this.$toast("获取信息失败")
					}
				})
			}
		}
	}
</script>