<template>
	<div class="modal-background1">
		<div class="modal1">
			<h2 class="title">用户信息</h2>
			<el-form ref="userDialogFormRef" :model="ruleForm" size="default" label-width="90px">
				<el-row :gutter="35">
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="登录名">
							<el-input v-model="ruleForm.loginName" placeholder="请输入登录名" clearable
								:disabled="dialog.editMode"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="用户名">
							<el-input v-model="ruleForm.userName" placeholder="请输入用户名" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="角色">
							<el-select v-model="ruleForm.roleId" placeholder="请选择" class="w100">
								<el-option v-for="item in roleData" :key="item.value" :label="item.label"
									:value="item.value" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="手机号">
							<el-input v-model="ruleForm.phone" placeholder="请输入手机号" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="性别">
							<el-select v-model="ruleForm.sex" placeholder="请选择" class="w100">
								<el-option v-for="item in sexData" :key="item.value" :label="item.label"
									:value="item.value" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="用户密码">
							<el-input v-model="ruleForm.pwd" placeholder="请输入密码" type="password"
								:disabled="dialog.editMode" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
						<el-form-item label="用户状态">
							<el-switch v-model="ruleForm.statusBool" inline-prompt active-text="启"
								inactive-text="禁"></el-switch>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
						<el-form-item label="备注">
							<el-input v-model="ruleForm.describe" type="textarea" placeholder="请输入用户描述"
								rows="8"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			
				<span class="dialog-footer">
					<el-button @click="onCancel" size="default">取 消</el-button>
					<el-button type="primary" @click="onSubmit" size="default">保  存</el-button>
				</span>
			
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus';
	import axios from 'axios';

	export default {
		components: {

		},
		props: {
			info: Object
		},
		
		data() {
			return {
				ruleForm: {
					staffId:0,
					loginName: '', // 账户名称
					userName: '', // 用户昵称
					roleId: 1, // 关联角色
					phone: '', // 手机号
					sex: 1, // 性别
					pwd: '', // 账户密码
					statusBool: true, // 用户状态
					describe: '', // 用户描述
				},
				roleData: [],
				sexData: [],
				dialog: {
					isShowDialog: false,
					editMode: false,
					title: '',
					submitTxt: '',
				},
			};
		},
		created(){
			this.getSexList();
			this.getRoleList();
		},
		mounted() {
			let info = this.info;
			console.log(info);
			if (info != null) {
				this.dialog.editMode=true;
				this.ruleForm.staffId = info.staffId;
				this.ruleForm.loginName = info.loginName;
				this.ruleForm.userName = info.userName;
				this.ruleForm.roleId = info.roleId;
				this.ruleForm.phone = info.phone;
				this.ruleForm.sex = info.sex;
				this.ruleForm.pwd = info.pwd;
				this.ruleForm.statusBool = info.statusBool;
				this.ruleForm.describe = info.describe;
			} else {
				this.dialog.editMode=false;
				this.ruleForm.loginName = '';
				this.ruleForm.userName = '';
				this.ruleForm.roleId = 1;
				this.ruleForm.phone = '';
				this.ruleForm.sex = 1;
				this.ruleForm.pwd = '';
				this.ruleForm.statusBool = true;
				this.ruleForm.describe = '';
			}
		},
		methods: {
			getRoleList() {
				let that=this;
				axios({
						url: '/getRoleList',
						method: 'post'
					}).then(function(res) {
						if (res.data[0].code >= 0) {
							let resData=JSON.parse(res.data[0].data) ;
							const data = [];
							for (var i = 0; i < resData.length; i++) {
								data.push({
									label: resData[i].Role_Name,
									value: resData[i].Role_ID,
								});
							}
							that.roleData = data;
						} else {
							ElMessage.warning('获取角色列表失败！');
						}
					})
					.catch(() => {});
			},

			getSexList() {
				let that=this;
				axios({
						url: '/getParamList',
						method: 'post',
						data: {
							typeId: 3
						}
					}).then(function(res) {
						if (res.data[0].code >= 0) {
							let resData=JSON.parse(res.data[0].data) ;
							const data = [];
							for (var i = 0; i < resData.length; i++) {
								data.push({
									label: resData[i].Param_Name,
									value: resData[i].Param_Key,
								});
							}
							that.sexData = data;
						} else {
							ElMessage.warning('获取性别列表失败！');
						}
					})
					.catch(() => {});
			},

			// 关闭弹窗
			closeDialog() {
				this.$emit('closeDlg');
			},
			// 取消
			onCancel() {
				this.closeDialog();
			},
			// 提交
			onSubmit() {
				let that=this;
				if (this.ruleForm.loginName.trim() == '') {
					ElMessage.error('登录名不能为空');
					return false;
				}
				if (this.ruleForm.userName.trim() == '') {
					ElMessage.error('用户名不能为空');
					return false;
				}
				if (this.ruleForm.roleId == 0) {
					ElMessage.error('角色不能为空');
					return false;
				}
				if (this.ruleForm.sex == 0) {
					ElMessage.error('性别不能为空');
					return false;
				}

				var status = 0;
				if (this.ruleForm.statusBool) {
					status = 1;
				} else {
					status = 2;
				}

				if (!this.dialog.editMode) {
					if (this.ruleForm.pwd.trim() == '') {
						ElMessage.error('密码不能为空');
						return false;
					}
					let data = {
						loginName: this.ruleForm.loginName,
						staffName: this.ruleForm.userName,
						pwd: this.ruleForm.pwd,
						mobile: this.ruleForm.phone,
						roleId: this.ruleForm.roleId,
						state: status,
						sex: this.ruleForm.sex,
						memo: this.ruleForm.describe
					}
					axios({
							url: '/addStaff',
							method: 'post',
							data: data
						}).then(function(res) {
							if (res.data[0].code > 0) {
								ElMessage.success('新增用户成功！');
								that.closeDialog();
							} else {
								ElMessage.warning('新增用户失败!');
							}
						})
						.catch(() => {});
				} else {
					let data = {
						staffId: this.ruleForm.staffId,
						loginName: this.ruleForm.loginName,
						staffName: this.ruleForm.userName,
						mobile: this.ruleForm.phone,
						roleId: this.ruleForm.roleId,
						state: status,
						sex: this.ruleForm.sex,
						memo: this.ruleForm.describe
					}
					axios({
							url: '/updateStaff',
							method: 'post',
							data: data
						}).then(function(res) {
							if (res.data[0].code > 0) {
								ElMessage.success('修改用户信息成功！');
								that.closeDialog();
							} else {
								ElMessage.warning('修改用户信息失败!');
							}
						})
						.catch(() => {});
				}
			}
		}
	};
</script>