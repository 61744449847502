<template>
	<el-row class="tac">
		<el-input size="default" v-model="qryName" placeholder="请输入域名" style="max-width: 180px;margin-right: 10px;">
		</el-input>
		<el-select v-model="qryState" style="width: 120px;margin-right: 10px;">
			<el-option key=1 label="可用" value=1 />
			<el-option key=2 label="不可用" value=2 />
			<el-option key=0 label="所有" value=0 />
		</el-select>
		<el-button size="default" type="primary" class="ml10" @click="getList">
			<el-icon>
				<ele-Search />
			</el-icon>
			查询
		</el-button>
		<el-button size="default" type="success" class="ml10" @click="onOpenAdd()">
			<el-icon>
				<ele-FolderAdd />
			</el-icon>
			新增域名
		</el-button>
	</el-row>
	<el-row>
		<el-table :data="tableData.data" v-loading="tableData.loading" style="width: 100%;height: 750px;">
			<el-table-column type="index" label="序号" width="60" />
			<el-table-column prop="hostName" label="域名" width="240" show-overflow-tooltip></el-table-column>
			<el-table-column prop="stateName" label="状态" width="100" show-overflow-tooltip></el-table-column>
			<el-table-column prop="createTime" label="创建时间" width="180" show-overflow-tooltip></el-table-column>
			<el-table-column prop="updateTime" label="更新时间" width="180" show-overflow-tooltip></el-table-column>
			<el-table-column prop="memo" label="备注" show-overflow-tooltip></el-table-column>
			<el-table-column label="操作" width="220">
				<template #default="scope">
					<el-button :disabled="scope.row.state!=1" size="small" type="primary"
						@click="onOpenEdit(scope.row)">修改</el-button>
					<el-button :disabled="scope.row.state!=1" size="small" type="success"
						@click="onUpdateState(scope.row.hostId,2)">失效</el-button>
					<el-button :disabled="scope.row.state!=1" size="small" type="warning"
						@click="onUpdateState(scope.row.hostId,3)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
	</el-row>
	<el-row>
		<el-pagination @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange" class="mt15"
			:pager-count="5" :page-sizes="[10, 20, 30]" v-model:current-page="tableData.param.pageNum" background
			v-model:page-size="tableData.param.pageSize" layout="total, sizes, prev, pager, next, jumper"
			:total="tableData.total">
		</el-pagination>
	</el-row>
	<staffDlg v-if="showDlg" @closeDlg="closeDlg" :info="selectInfo" />
</template>

<script>
	import moment from 'moment';
	import axios from 'axios';
	import staffDlg from './hostDlg.vue';
	export default {
		components: {
			staffDlg
		},
		data() {
			return {
				showDlg: false,
				selectInfo: {},
				qryName: '',
				qryState:'1',
				tableData: {
					data: [],
					total: 0,
					loading: false,
					param: {
						pageNum: 1,
						pageSize: 10,
					},
				}
			}
		},
		mounted() {
			this.getList();
		},
		methods: {
			getList() {
				this.tableData.loading = true;
				let that = this;
			
				axios({
						url: '/getHostList',
						method: 'post',
						data: {
							hostName: this.qryName,
							state:this.qryState,
							pageNum: this.tableData.param.pageNum,
							pageSize: this.tableData.param.pageSize
						}
					}).then(function(res) {
						if (res.data[0].code) {
							let resData = JSON.parse(res.data[0].data);
							for (var i = 0; i < resData.data.length; i++) {
								resData.data[i].createTime = moment(resData.data[i].createTime).format(
									'YYYY-MM-DD HH:mm:ss');
								resData.data[i].updateTime = resData.data[i].updateTime == null ? '' : moment(resData
									.data[i].updateTime).format(
									'YYYY-MM-DD HH:mm:ss');
							}
							that.tableData.data = resData.data;
							that.tableData.total = resData.total;
						} else {
							that.$toast('没有符合条件的数据！');
						}
						that.tableData.loading = false;
					})
					.catch((e) => {
						console.log(e)
						this.$toast('获取列表失败！');
						this.tableData.loading = false;
					});
			},

			// 打开新增用户弹窗
			onOpenAdd() {
				this.selectInfo = null;
				this.showDlg = true;
			},
			// 打开修改用户弹窗
			onOpenEdit(row) {
				this.selectInfo = row;
				this.showDlg = true;
			},
			closeDlg() {
				this.showDlg = false;
				this.getList();
			},
			onUpdateState(hostId, state) {
				let that = this;
				axios({
						url: '/updateHostState',
						method: 'post',
						data: {
							hostId: hostId,
							state: state
						}
					}).then(function(res) {
						if (res.data[0].code > 0) {
							that.getList();
							that.$toast('操作成功');
						} else {
							that.$toast('操作失败');
						}
					})
					.catch((e) => {
						console.log(e)
					});
			},

			// 分页改变
			onHandleSizeChange(val) {
				this.tableData.param.pageSize = val;
				this.getList();
			},
			// 分页改变
			onHandleCurrentChange(val) {
				this.tableData.param.pageNum = val;
				this.getList();
			}
		}
	}
</script>