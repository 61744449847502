<template>
	<div class="modal-background">
		<div class="modal">
			<span class="close" @click="closeMe">&times;</span>
			<h2 class="title">客服统计</h2>
			<el-form :inline="true" :model="service" class="demo-form-inline" style="display: flex;">
				<el-form-item label="起始日期">
					<el-date-picker v-model="startDate" type="date" placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="结束日期">
					<el-date-picker v-model="endDate" type="date" placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-button type="success" v-on:click="getAccessList()">查 询</el-button>
			</el-form>

			<div class="container">
				<BaseTable :config="config" :data="tableData" :style="{width:'100%',height:'100%'}">
				</BaseTable>
				<!-- <el-pagination background :total="tableData.length" v-model:current-page="pagination.currentPage"
					v-model:page-size="pagination.pageSize" @current-change="getAccessList" @size-change="getAccessList"
					class="pagination">
				</el-pagination> -->
			</div>
		</div>
	</div>
</template>

<script>
	import BaseTable from './BaseTable.vue';
	import moment from 'moment';
	import axios from 'axios';
	export default {
		components: {
			BaseTable
		},
		data() {
			return {
				startDate: '',
				endDate: '',
				pagination: {
					currentPage: 1,
					pageSize: 10,
				},
				service: {},
				config: [{
						prop: 'joinTime',
						label: '加入时间',
						width: '180'
					},
					{
						prop: 'leaveTime',
						label: '离开时间',
						width: '180'
					},
					{
						prop: 'times',
						label: '接入时长',
						width: '120'
					},
					{
						prop: 'ip',
						label: 'IP',
						width: '120'
					},
					{
						prop: 'userName',
						label: '客户',
						width: '100'
					},
					{
						prop: 'location',
						label: '位置',
						width:'180'
					},
					{
						prop: 'plat',
						label: '终端'
					},
				],
				tableData: []
			}
		},
		mounted() {
			this.service = Object.assign({}, JSON.parse(localStorage.getItem('serviceData')));
			this.startDate=Date();
			this.endDate=Date();
			this.getAccessList();
		},
		methods: {
			closeMe() {
				this.$emit('closeStat');
			},
			getAccessList() {
				let params = {
					serviceId: this.service.serviceId,
					startDate: moment(this.startDate).format('YYYY-MM-DD')+' 0:00:00',
					endDate:moment(this.endDate).format('YYYY-MM-DD')+' 23:59:59',
					page: this.pagination.currentPage
				}
				axios({
					method: 'post',
					url: '/getAccessList',
					data: params
				}).then((response) => {
					if (response.data[0].code) {
						this.tableData = JSON.parse(response.data[0].data);
						for (var i=0;i<this.tableData.length;i++){
							this.tableData[i].joinTime= this.tableData[i].joinTime==null?'':moment(this.tableData[i].joinTime).format('YYYY-MM-DD HH:mm:ss') ;
							this.tableData[i].leaveTime=this.tableData[i].leaveTime==null?'':moment(this.tableData[i].leaveTime).format('YYYY-MM-DD HH:mm:ss') ;
						}
						if (JSON.parse(response.data[0].data).length == 10) {
							this.pagination.pageSize = this.pagination.pageSize + 1
						}
					} else {
						this.$toast("获取信息失败")
					}
				})
			}
		}
	}
</script>